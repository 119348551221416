@import "variables";
@import "mixins";

.widget-slot-panel .widget:first-child coyo-media-widget {
  .media-list.full {
      border-top-right-radius: $border-radius-base;
      border-top-left-radius: $border-radius-base;
  }

  @include sm-and-down() {
    .media-list.full {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}


.widget-slot-panel .widget:last-child coyo-media-widget {

  .media-list {
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
  }

  @include sm-and-down() {
    .media-list {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

coyo-media-widget-settings {
  .album {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    .mat-form-field-wrapper {
      margin: 0;
    }
  }
}
