.list-entry-details-modal {
  .modal-header {
    border: 0;
  }

  .modal-mobileheader {
    min-height: 80px;
    height: 80px;
    display: flex;

    @media print, screen and (min-width: $screen-sm-min) {
      display: none;
    }

    .tab {
      border-bottom: 5px solid $color-gray-dark;
      flex: 1;
      cursor: pointer;

      a {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        color: $color-gray-dark;
      }

      &.active {
        border-color: $color-blue;

        a {
          color: $color-blue;
        }
      }

      i {
        font-size: 1.5em;
      }

      span {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .modal-subheader {
    height: 40px;
    background-color: $color-gray-lighter;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 24px;
    border-bottom: 1px solid $color-gray-light;

    @media print, screen and (min-width: $screen-sm-min) {
      border-top: 1px solid $color-gray-light;
    }

    .action {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 14px 0;

      &:last-child {
        padding-right: 0;
      }

      @media print, screen and (min-width: $screen-sm-min) {
        & + .action {
          border-left: 1px solid $color-gray-light;
        }
      }
    }
  }

  .modal-body.list-entry-details {
    padding-top: 0px;
  }

  .modal-body.list-entry-comments-tab {
    .comments {
      margin: -16px;
    }
  }

  .modal-body.list-entry-history {
    padding: 50px 24px;

    @media screen and (min-width: $screen-sm-min) {
      padding-left: 120px;
      padding-right: 120px;
    }

    @media print, screen and (min-width: $screen-md-min) {
      padding-left: 160px;
      padding-right: 160px;
    }

    display: flex;
    flex-wrap: wrap;

    .icon-wrapper {
      height: 85px;
      width: 85px;
      border-radius: 50%;
      background-color: $color-gray-lighter;
      color: $color-gray-dark;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 2.5em;
        font-weight: 600;

        &.zmdi-plus {
          font-size: 3.2em;
        }
      }
    }

    label {
      margin: 15px 0 15px;
      color: $color-gray;
      text-transform: uppercase;
      font-weight: 600;
    }

    .user-field-wrapper{
      padding: 0;
      margin: 0;
    }

    p.list-entry-history-date {
      color: $color-gray;
    }

    span.list-entry-history-sender-error-message {
      color: $color-gray-dark;
    }

    .col-flex {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .mobile-spacer {
      width: 100%;
      margin: 30px 0;
    }
  }

  .modal-details-edit-body {
    overflow: auto;
  }
}

.list-entry-modal-comments {
  .comments {
    margin: 0;
  }
}
