@import "variables";

.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker,
bs-datepicker-container,
.bs-datepicker-container {
  border-radius: $border-radius-base $border-radius-base $border-radius-base $border-radius-base;
}

.theme-coyo {
  .bs-datepicker-head {
    border-radius: $border-radius-base $border-radius-base 0 0;
  }
  .bs-datepicker-body {
    border-radius: 0 0 $border-radius-base $border-radius-base;
  }
  .bs-datepicker-head,
  .bs-datepicker-body table td span.selected {
    background-color: $coyo-link-color;
  }
  .bs-datepicker-body table td.week span {
    color: $coyo-focus-color;
  }
}
