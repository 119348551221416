.coyo-spinner {
  display: inline-block;
  margin: 8px auto;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid rgba(0, 0, 0, 0.5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.2s infinite linear;
  animation: load8 1.2s infinite linear;
  overflow: hidden;

  font-size: 2px;
  &.xs { font-size: 2px; }
  &.sm { font-size: 2px; }
  &.lg { font-size: 3px; }
  &.xl { font-size: 4px; }

  @include sm() {
    font-size: 3px;
    &.xs { font-size: 2px; }
    &.sm { font-size: 2px; }
    &.lg { font-size: 4px; }
    &.xl { font-size: 6px; }
  }

  @include md-and-up() {
    font-size: 4px;
    &.xs { font-size: 2px; }
    &.sm { font-size: 3px; }
    &.lg { font-size: 6px; }
    &.xl { font-size: 8px; }
  }

  &.inverted {
    border-top-color: rgba(255, 255, 255, 0.25);
    border-right-color: rgba(255, 255, 255, 0.25);
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-left-color: rgba(255, 255, 255, 1);
  }
}

.coyo-spinner,
.coyo-spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

@include keyframes(load8) {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
