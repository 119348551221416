.nav {
  > * > li,
  > li {
    > a,
    > * > a {
      color: $color-gray-darker;
      transition: background-color linear 0.1s;

      .badge.pull-right {
        margin-top: 2px;
      }
    }

    &.active > a, &.active > a:hover,
    &.active > * > a, &.active > * > a:hover {
      color: $text-color;
      background-color: $color-gray-lighter;
    }

    &:not(.active) > a:focus,
    &:not(.active) > * > a:focus {
      background-color: $color-white;

      &:hover {
        background-color: $color-gray-lighter;
      }
    }

    &.disabled > a,
    &.disabled > * > a {
      color: $color-gray-light;
    }
  }
}

.nav.nav-default {
  > * > li,
  > li {
    > a,
    > * > a {
      border-left: 3px solid transparent;

      &:hover {
        border-color: $color-gray-light;
        background-color: $color-gray-lighter;
        @media screen and (max-width: $screen-lg-max) {
          background-color: $color-white;
          border-left: 3px solid transparent;
        }
      }
    }

    &.active > a, &.active > a:hover,
    &.active > * > a, &.active > * > a:hover {
      border-color: $btn-primary-bg;
      background-color: $color-gray-lighter;
    }

    &:not(.active) > a:focus,
    &:not(.active) > * > a:focus {
      background-color: $color-white;

      &:hover {
        background-color: $color-gray-lighter;
        @media screen and (max-width: $screen-lg-max) {
          background-color: $color-white;
          border-left: 3px solid transparent;
        }
      }
    }

    &.divider {
      border-bottom: 1px solid $color-gray-light;
      padding: 12px 0 0 0;
      margin: 0 0 12px 0;
    }
  }
}

.nav.nav-icons {
  > * > li,
  > li {
    > a,
    > * > a {
      padding-left: 43px;

      > i {
        float: left;
        margin-left: -28px;
        text-align: center;
        line-height: 24px;
        width: 20px;
      }

      .mat-icon {
        float: left;
        line-height: 1.5em;
        margin-left: -28px;
      }

      .filter-entry-actions .mat-icon {
        margin-left: 0;
      }

      > coyo-icon {
        fill: $color-gray-darker;
        float: left;
        margin-left: -28px;
        align-items: center;
        width: 20px;
        height: 24px;
        justify-content: center;
      }
    }
  }
}

.nav.nav-tabs, .nav.nav-tabs.nav-justified {
  border: 0;
  border-bottom: 3px solid $color-gray-light;

  > li {
    overflow: hidden;
    margin: 0 0 -3px 0;
    padding: 0;
  }

  > li > a,
  > li > * > a {
    margin: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 3px solid transparent;
    color: #697687;
    @include ellipsis();

    &:hover, &:focus, &:active {
      background-color: transparent;
      border-bottom: 3px solid $color-gray;
    }
  }

  > li.disabled > a,
  > li.disabled > * > a {
    border-color: transparent;
    color: $color-gray-light;
  }

  > li.active > a,
  > li.active > * > a {
    border: 0;
    color: $text-color;
    border-bottom: 3px solid $color-blue;
    background-color: transparent;
  }
}

.nav.nav-tabs.nav-justified {
  display: flex;

  > li {
    display: block;
    width: auto;
    flex: 1;
  }
}

.panel {
  .nav {
    > li > a,
    > li > * > a,
    &.nav-panel > li > a,
    &.nav-panel > li > * > a {
      padding: 6px 30px 6px 26px;
    }
    &.nav-icons > li > a, &.nav-icons.nav-panel > li > a,
    &.nav-icons > li > * > a, &.nav-icons.nav-panel > li > * > a {
      padding-left: 52px;
    }
  }

  &.panel-sm {
    .nav {
      > li > a,
      > li > * > a,
      &.nav-panel > li > a,
      &.nav-panel > li > * > a {
        padding-left: 22px;
        padding-right: 24px;
      }
      &.nav-icons > li > a, &.nav-icons.nav-panel > li > a,
      &.nav-icons > li > * > a, &.nav-icons.nav-panel > li > * > a {
        padding-left: 46px;
      }
    }
  }

  &.panel-xs {
    .nav {
      > li > a,
      > li > * > a,
      &.nav-panel > li > a,
      &.nav-panel > li > * > a {
        padding-left: 18px;
        padding-right: 15px;
      }
      &.nav-icons > li > a, &.nav-icons.nav-panel > li > a,
      &.nav-icons > li > * > a, &.nav-icons.nav-panel > li > * > a {
        padding-left: 40px;
      }
    }
  }
}
