.options-field {

  .option-field-item {
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .input-group-addon.disabled:hover {
    cursor: not-allowed;
  }

  .delete-option:hover {
    cursor: pointer;

    > .zmdi {
      color: darken($color-gray-darker, 25%);
    }
  }

  .add-button {
    color: $color-blue;
    text-align: start;
    display: block;
    width: 100%;
    height: 40px;
    padding: 7px 14px;
    font-size: 15px;
    line-height: 1.6;
    background: #fff none;
    border: 1px solid $color-gray-light;
    border-radius: 5px;

    > .add-answer-icon {
      padding-right: 5px;
    }
  }
}

.angular-ui-tree-drag {
  .option-field-item {
    margin: 0;
  }
}