.modal-image {
  .image {
    text-align: center;
    padding: 24px;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
}
