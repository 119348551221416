// DESKTOP
.panel-navigation {
  .panel {
    @media print, screen and (min-width: $screen-md-min) {
      padding-bottom: 16px;
    }

    .panel-body {
      position: relative;

      &:hover .btn-delete-group {
        display: block;
        right: 8px;
        top: 10px;
      }
      &:hover .btn-settings {
        display: block;
        right: 32px;
        top: 10px;
      }
    }
  }
}
