@import "variables";

// Variables
.modal.modal-file-details.small-sm {
  display: block;
  .modal-dialog {
    height: 100%;

    @media screen and (max-width: $screen-md) {
      position: relative;
    }

    @media print, screen and (min-width: $screen-md-min) {
      bottom: 0;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    .modal-content {
      @media print, screen and (min-width: $screen-md-min) {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
      }

      .modal-content-wrapper {
        display: flex;
        flex-direction: column;

        @media print, screen and (min-width: $screen-md-min) {
          background-color: $color-white;
          bottom: 0;
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .2);
          position: absolute;
          overflow-y: auto;
          right: 0;
          top: 0;
          width: $size-sidebar-file-details;
        }

        .modal-header {
          min-height: 53px;
          border: none;
          flex: 0 0 auto;

          .modal-info {
            margin-top: 18px;
          }

          @media print, screen and (min-width: $screen-md-min) {
            min-height: 70px;
            position: relative;

            .modal-info {
              display: none;
            }
          }

          .modal-title {
            font-size: 20px;
            height: 43px;

            small.file-type {
              display: block;
              font-weight: bold;
              text-transform: uppercase;
              color: $n0;

              @media screen and (min-width: $screen-md-min) {
                color: $n200;
              }
            }

            .headline-wrapper {
              display: flex;
              align-items: center;
            }

            .name-wrapper {
              @include ellipsis();
            }
          }

          .zmdi-close {
            cursor: pointer;
          }
        }

        .file-counter {
          flex: 0 0 auto;
          display: block;
          text-align: center;
          padding: 12px;
          font-size: 16px;
          font-weight: lighter;
          color: $coyo-modal-header-text;
          background-color: $color-secondary;

          span {
            padding: 9px;
          }

          @media print, screen and (min-width: $screen-md-min) {
            top: 0;
            left: 0;
            position: fixed;
            right: $size-sidebar-file-details;
            padding: 14px;
            background-color: $coyo-modal-header-bg;
          }
        }

        .modal-body {
          flex: 1 0 0;
          overflow-y: auto;
          padding: 0 !important;

          @media print, screen and (min-width: $screen-md-min) {
            overflow-y: auto;
          }

          .tab-bar {
            margin-bottom: 15px;

            .nav-tabs > li {
              text-align: center;
            }
          }

          .modal-file-preview {
            text-align: center;

            .pdf-loading-hint {
              padding-bottom: 16px;
              color: $color-link;

              &:hover, &:focus {
                color: $color-link-hover;
              }
            }

            .preview-image {
              height: 100px;
              margin: 0 auto;
              width: 100px;
            }

            .fd-preview {
              width: 100%;
              min-height: 210px;

              .pi-preview-pdf-container coyo-pdf-viewer {
                #viewerContainer, .ng2-pdf-viewer-container {
                  position: static;
                }

                coyo-pdf-viewer-toolbar {
                  left: 0;
                  width: 100%;

                  .toolbar-container {
                    background-color: $color-secondary;
                  }
                }

                pdf-viewer {
                  @media print, screen and (min-width: $screen-md-min) {
                    padding-top: 50px;
                  }
                }
              }
            }

            &.multifile {

              .fd-preview coyo-pdf-viewer coyo-pdf-viewer-toolbar .toolbar-container {
                background-color: $coyo-navbar;
              }
            }

            @media print, screen and (min-width: $screen-md-min) {
              overflow-y: auto;
              max-height: 100%;
              bottom: 0;
              left: 0;
              position: fixed;
              right: $size-sidebar-file-details;
              top: 0;
              padding-top: 0;

              &.multifile {
                top: 50px;
                padding-top: 0;

                .fd-preview .pi-preview-pdf-container coyo-pdf-viewer coyo-pdf-viewer-toolbar {
                  top: 50px;
                  .toolbar-container {
                    background-color: $color-secondary;
                  }
                }
              }

              .fd-preview .pi-preview-pdf-container coyo-pdf-viewer coyo-pdf-viewer-toolbar {
                left: 0;
                right: $size-sidebar-file-details;
                width: calc(100% - #{$size-sidebar-file-details});

                .toolbar-container {
                  background-color: $coyo-navbar;
                }
              }
            }
            .pdf-full-screen {
              @media screen and (max-width: $screen-md-min) {
                padding: 0 !important;
              }
            }
            .fd-preview {
              padding: 16px;

              .preview-container {
                @media print, screen and (min-width: $screen-md-min) {
                  min-height: calc(100vh - 82px);
                }
                display: flex;
                justify-content: center;
              }

              .pi-container {
                max-width: 80%;
                height: unset;
                min-height: 210px;
                @media screen and (max-width: $screen-md-min) {
                  max-width: 100%;
                }
                display: flex;
                align-items: flex-start;
                justify-content: center;

                &.loading,
                &.unavailable,
                &.processing,
                &.video {
                  border: 0 none;
                  background: none;
                }

                &.unavailable,
                &.loading {
                  align-self: center;
                }

                &.unavailable:not(.loading),
                &.processing:not(.loading) {
                  @media print, screen and (min-width: $screen-md-min) {
                    background-color: $color-white;
                    padding: 50px;
                    border-radius: 5px;
                    height: auto;
                    width: auto;
                    margin: auto;
                  }

                  .alert {
                    margin: 0;
                  }
                }

                @media print, screen and (min-width: $screen-md-min) {
                  background-image: none;
                  border: 0 none !important;

                  &:not(.unavailable) .pi-preview-image-container,
                  &:not(.unavailable) .pi-preview-video-container {
                    margin-top: 20px;
                  }
                }

                .pi-spinner-container {
                  @media print, screen and (min-width: $screen-md-min) {
                    align-self: center;
                    background-color: $color-white;
                    border-radius: 10px;
                    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
                    height: 76px;
                    left: 50%;
                    margin: -38px 0 0 -38px;
                    padding: 10px;
                    position: absolute;
                    width: 76px;
                  }
                }
              }

              img {
                max-width: 100%;
              }

              coyo-file-preview {
                display: block;
              }

              .error-container {
                background-color: $color-white;
                padding: 20px 25px 0;
                width: 80%;
                margin: 20px auto -20px;

                .alert.alert-danger {
                  margin-bottom: 0;
                }
              }
            }
          }

          coyo-library-file-details {
            display: block;
          }

          .file-details-action-bar {
            background-color: $color-gray-lighter;
            border-top: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            margin-bottom: 15px;

            &.attachment-action-bar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              min-height: 42px;
              margin-top: 16px;
              @media screen and (max-width: $screen-md-min) {
                margin-top: 0;
              }

              a {
                display: inline-block;
                padding: 8px 9px;
              }
            }

            @include sm-and-up() {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              .edit-in-office-link {
                flex: 1;
                text-align: left;
                padding: 0 12px;

                &.is-locked {
                  pointer-events: none;
                  cursor: default;
                }
              }
            }

            .btn {
              padding: 8px 9px;
            }

            .zmdi-cloud-upload {
              color: $color-gray-darker;
              font-size: 20px;
              padding-top: 2px;
            }
          }

          .fd-file-actions {
            border-bottom: 1px solid $color-gray-light;
            border-top: 1px solid $color-gray-light;
            margin-bottom: 16px;
            text-align: right;

            @media print, screen and (min-width: $screen-md-min) {
              border-top: 0;
            }

            .btn-zmdi {
              padding-left: 8px;
              padding-right: 8px;
            }

            .zmdi {
              font-size: 140%;
              vertical-align: middle;
            }
          }

          .fd-file-options {
            border-top: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            margin-top: 4px;
            padding: 4px 8px;
            width: 100%;

            .context-menu {
              float: right;
            }
          }

          .comments {
            margin: 0;

            .comments-list.panel-body {
              border-top: 0;
            }
          }

          .file-versions {
            width: 100%;

            td {
              vertical-align: top;
              padding: 12px 12px 12px 0;

              &:first-child {
                padding-left: 12px;
                vertical-align: middle;
              }

              &:last-child,
              &:first-child {
                width: 1px;
              }
            }

            tr {
              border: 1px solid $color-gray-light;
              border-right-style: none;
              border-left-style: none;
            }

            tr.active {
              background-color: $color-gray-lighter;

              .version-number {
                background-color: $color-green;
                cursor: default;
              }
            }

            .version-number {
              $version-circle-size: 40px;
              background-color: $color-gray-light;
              width: $version-circle-size;
              height: $version-circle-size;
              line-height: $version-circle-size;
              border-radius: 50%;
              text-align: center;
              cursor: pointer;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}


.file-details-action-bar {
  a {
    display: flex;
    align-items: center;
  }
}
