@import "mixins";
@import "variables";

.single-blog-article-widget {
  picture img {
    width: 100%;
  }
}

// Stretch the image over widget border
// only in separate widget slots
.widget-slot-panels,
.widget-slot-panel {
  .widgets-container .widget {

    .single-blog-article-widget {

      .article-teaser-image {
        margin: $panel-body-padding-negate-sm;
        margin-bottom: 0;
        overflow: hidden;

        @media print, screen and (min-width: $screen-sm-min) {
          margin: $panel-body-padding-negate-md;
          margin-bottom: 0;
        }

      }
    }

    &:first-child .single-blog-article-widget .article-teaser-image,
    .panel .single-blog-article-widget .article-teaser-image {
      @media print, screen and (min-width: $screen-md-min) {
        border-radius: $panel-border-radius;
      }
    }
  }
}
