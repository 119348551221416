// Animations - Fade in
// --------------------------------------------------

@include keyframes(fadein) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  @include animate(fadein 2s);
}
