// Login
// --------------------------------------------------

.backend-url-reset {
  position: absolute;
  top: 11px;
  right: 12px;

  .zmdi {
    font-size: 20px;
    color: $color-gray-darker;

    &:hover {
      color: $color-red;
    }
  }
}

.auto-login-msg {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.5);
  font-size: 20px;
}

#login-error {
  padding: 12px;
}