// Animations - Pulse
// --------------------------------------------------

@include keyframes(pulse) {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animation-pulse {
  @include animate(pulse 1s);
}
