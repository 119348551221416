// Mixins - Set up a hyphenate-break mixin
// ---------------------------------------

// example usage:
// @include hyphenate-break()
@mixin hyphenate-break() {
  -ms-word-break: break-all;
  // order is important!
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
