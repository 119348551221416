// Mixins - Set up ellipsis
// --------------------------------------------------

// example usage:
// @include ellipsis()
@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @include ellipsis-multiline(2, 48px)
@mixin ellipsis-multiline($lines, $max-height) {
  overflow-y: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  // https://github.com/angular/angular-cli/issues/14393
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  max-height: $max-height;
}
