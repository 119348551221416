@import "variables";

@import '~@coyo/ui/lib/mixins';
@import '../../node_modules/@coyoapp/ui/styles/mixins';
@import "../../../src/main/styles/mixins";
@import "components/mixins/buttons";
@import "components/mixins/icons";
@import "components/mixins/tabs";
@import "components/mixins/widgets";

// You can add your mixins here

/**
 * Allows easy definition of multiple columns of text.
 */
@mixin columns($columns, $column-gap: 0) {
  -webkit-columns: $columns;
  -moz-columns: $columns;
  columns: $columns;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

/**
 * Makes text unselectable.
 */
@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

/**
 * Visually hides the content while keeping it accessible for tab focus.
 *
 * see: https://a11yproject.com/posts/how-to-hide-content/
 */
// TODO: we might be able to replace this with .cdk-visually-hidden from the CDK's a11y package
@mixin visually-hidden() {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

/**
 * Creates a loading skeleton.
 * DEPRECATED: use the skeleton component instead
 *
 * Usage:
 * @include skeleton((
 *   (x: 10px, y: 10px, width: 40px, height: 40px),
 *   (x: 60px, y: 10px, width: 60px, height: 15px),
 *   (x: calc(100% - 10px), y: 10px, width: 80px, height: 12px),
 *   (x: 10px, y: 60px, width: 80%, height: 12px),
 *   (x: 10px, y: 80px, width: 60%, height: 12px),
 *   (x: 10px, y: 100px, width: 90%, height: 12px)
 * ), 140px);
 *
 * Result:
 *  #### ######                        #########
 *  ####
 *
 *  ##################################
 *  ########################
 *  #########################################
 */
@mixin skeleton(
  $objects: (),
  $element-height: 150px,
  $color: $n35,
  $background: $n0,
  $highlight-height: $element-height
) {
  $positions: ();
  $gradients: ();
  $sizes: ();
  $rnd: unique-id();

  @each $obj in $objects {
    $positions: append($positions, #{map-get($obj, 'x') map-get($obj, 'y')}, 'comma');
    $gradients: append($gradients, linear-gradient($color #{map-get($obj, 'height')}, transparent 0), 'comma');
    $sizes: append($sizes, #{map-get($obj, 'width') $element-height}, 'comma');
  }

  @at-root {
    @keyframes loading-skeleton-animation-#{$rnd} {
      to {
        background-position: calc(100% + 50px) 0, #{$positions};
      }
    }
  }

  height: $element-height;
  background-image: linear-gradient(100deg, rgba($background, 0), rgba($background, 0.5) 50%, rgba($background, 0) 80%), #{$gradients};
  background-size: 50px $highlight-height, #{$sizes};
  background-position: 0 0, #{$positions};
  background-repeat: no-repeat;
  animation: loading-skeleton-animation-#{$rnd} 1.25s infinite;
}

// Print - Mixin
// --------------------------------------------------
//
// Mixin that determines display and styling properties for printing, and fixing the grid layout to one version.
// This is important because all browsers interprete the actual paper size
// differently (and sometimes as extremely narrow) and would thus use different breakpoints.
//
// It should be used for the actual @media print query
// as well as for elements that are displayed on screen but simulate the printing result (e.g. styling certain elements).
//
// example usage:
// @include print-display-widget-layout()
@mixin print-display-widget-layout() {

  @media (min-width: 0) {
    @include make-grid(md);
  }

  // Overwrite for print
  // Responsive: Visibility utilities from _responsive-utilities.scss
  //
  // assuming that layout should always look like lg even if media query for paper suggests smaller width
  // ------------------------------------------------------------------------------------------------------

  .visible-xs,
  .visible-xs-block,
  .visible-xs-inline,
  .visible-xs-inline-block,
  .visible-sm,
  .visible-sm-block,
  .visible-sm-inline,
  .visible-sm-inline-block,
  .visible-md,
  .visible-md-block,
  .visible-md-inline,
  .visible-md-inline-block {
    display: none !important;
  }

  @include responsive-visibility('.visible-lg');
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }

  @include responsive-visibility('.hidden-xs');
  @include responsive-visibility('.hidden-sm');
  @include responsive-visibility('.hidden-md');

  .widget-slot-options {
    display: none;
  }

  .panel-body {
    padding-bottom: 0;
  }

  coyo-poll-widget .vote-bar {
    border-top: solid 5px $color-green;
    border-bottom: solid 5px $color-green;
    // required for edge that does not print background-colors at all so we fake it with thick borders
  }

  coyo-rss-widget {
    .rss-entry {
      margin-left: -30px;
      margin-right: -30px;
      padding: 18px;
      margin: 0px -18px;
    }
  }

  .col-md-12 ,
  .col-md-8,
  .widget-slot-content {
    coyo-widget coyo-rss-widget {

      .rss-entry {
        display: flex !important;
        padding-right: 30px;

        .image {
          margin-right: 30px !important;
        }
      }
    }
  }

  .single-file-widget .single-file-widget-details {
    display: block !important;

    .single-file-widget-file-image {
      width: 100% !important;
    }

    .single-file-widget-details-text {
      width: initial !important;
      padding: initial !important;
      h4, .single-file-widget-details-infos {
        text-align: center !important;
      }
    }
  }
}

// Mixin that determines display of widgets for printing, hiding all elements that are not intended for printing.
//
// It should be used for the actual @media print query
// as well as for elements that are displayed on screen but simulate the printing result (e.g. hiding certain widgets).
//
// example usage:
// @include print-exclude-widgets()
@mixin print-exclude-widgets() {
  .widget:not(.printable) {
    display: none;
  }
}
