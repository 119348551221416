@import "variables";

coyo-time-picker {
  .popover-content {
    padding: 4px 16px;

    timepicker input {
      border-radius: $border-radius-base $border-radius-base $border-radius-base $border-radius-base !important;
    }
  }
  .popover {
    box-shadow: 0 0 10px #AAA;
    border-color: $n0;
    border-radius: $border-radius-base $border-radius-base $border-radius-base $border-radius-base;

    &.bottom {
      margin-top: 0;
      .popover-arrow.arrow {
        display: none;
      }
    }
  }
}
