coyo-download-widget {
  > :last-child.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;
    }
  }

  .mat-form-field-appearance-outline {

    .mat-form-field-wrapper {
      margin: 0 0 12px 0;
      padding-bottom: 0;
    }

    .mat-form-field-outline {
      top: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
      padding: 12px 8px;
    }

    .mat-form-field-outline {
      color: $n75;
    }
  }
}
