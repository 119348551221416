@import "variables";
@import "mixins";

//  Candidate for global table styles
$horizontal-table-padding: 4px;
$vertical-table-padding: 8px;

.file-picker-items-table {
  width: 100%;
  thead {
    color: $n200;
    font-size: 14px;
  }

  tbody {
    tr:hover {
      cursor: pointer;
      background-color: $n25;
    }
  }

  tr {
    td, th {
      padding: $vertical-table-padding $horizontal-table-padding;
      border-bottom: 1px solid $n50;
    }
  }
}

.mat-dialog-content .file-picker-items-table {
  margin-left: -$coyo-dialog-padding-horizontal;
  margin-right: -$coyo-dialog-padding-horizontal;
  width: calc(100% + #{$coyo-dialog-padding-horizontal} + #{$coyo-dialog-padding-horizontal});
  max-width: calc(100% + #{$coyo-dialog-padding-horizontal} + #{$coyo-dialog-padding-horizontal});

  tr {
    td:first-child, th:first-child {
      padding-left: $coyo-dialog-padding-horizontal;
    }

    td:last-child, th:last-child {
      padding-right: $coyo-dialog-padding-horizontal;
    }
  }
}

// End of candidate

$modified-column-width: 155px;
$size-column-width: 115px;
$icon-column-width: 32px;
$icon-column-width-first-child: 48px;
$selection-column-width: 46px;
$context-menu-column-width: 46px;

.file-picker-items-table {

  tr td {
    height: 65px;
  }

  table-layout: fixed;

  .col-modified {
    width: $modified-column-width;
  }

  .col-size {
    width: $size-column-width;
  }

  .col-icon {
    width: $icon-column-width;
  }

  tr td.col-selection, tr th.col-selection {
    width: $selection-column-width;

    &.download {
      * {
        visibility: hidden;
      }
      width: $coyo-dialog-padding-horizontal - $horizontal-table-padding * 2;
      padding-left: $coyo-dialog-padding-horizontal - $horizontal-table-padding * 2;
    }
  }

  .col-context-menu {
    width: $context-menu-column-width;
  }

  td {
    @include ellipsis();
  }
}
