.titles-container {
  flex: 1;
  padding: 12px 16px;
  width: 0;

  @media print, screen and (min-width: $screen-sm-min) {
    padding: 14px 24px 12px 24px;
  }

  @media print, screen and (min-width: $screen-md-min) {
    padding: 16px 24px;
  }

  .title {
    font-size: 20px;
    line-height: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .status {
      font-size: initial;
      vertical-align: middle;
    }

    @media print, screen and (min-width: $screen-sm-min) {
      font-size: 24px;
    }
  }

  .subtitle {
    color: $color-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
