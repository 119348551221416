.workspace {
  .title {
    .label-info {
      font-size: 12px;
      vertical-align: 3px;
    }
  }

  .panel-foreground {
    display: flex;
    align-items: center;
    margin-top: 22px;

    @media print, screen and (min-width: $screen-sm-min) {
      margin-top: 54px;
    }
  }

  .titles-container {
    overflow: hidden;

    .label {
      margin-top: 6px;
      margin-right: 4px;
    }

    .title {
      .workspace-visibility {
        margin-left: 12px;
      }
    }
    .subtitle {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 72px;
      white-space: pre-wrap;
      @include ellipsis-multiline(3, 72px);
    }
  }

  .workspace-members {
    margin-right: 24px;
  }

  .workspace-avatar-container {
    position: relative;
    margin: -22px 0 12px 16px;
    height: 120px;
    @media print, screen and (max-width: $screen-xs) {
      width: 92px;
      height: 92px;
    }

    @media print, screen and (min-width: $screen-sm-min) {
      margin: -54px 0 16px 24px;
    }

    .sender-avatar {
      border: 3px solid $color-white;
      background-color: $color-workspace-avatar-bg;
      border-radius: 5px;
      @media print, screen and (max-width: $screen-xs) {
        width: 92px;
        height: 92px;
      }
    }

    coyo-avatar-overlay {
      border-radius: 0 0 5px 5px;
    }
  }

  .workspace-join-button {
    min-width: 111px;
    margin-right: 24px;
  }

  .label-info {
    &.status {
      background-color: $coyo-ribbon-archived-bg;
    }
  }
  .display-attributes-container{
    display: flex;
    align-items: center;
    gap:16px;
  }
}
