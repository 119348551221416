.dynamic-entry-details-modal {
  .modal-body {
    min-height: 300px;
  }

  .modal-body.list-entry-details {
    padding-top: 0px;
  }

  div.table {

    > div {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;

      @media print, screen and (min-width: $screen-sm-min) {
        padding: 12px;

        &:first-child {
          padding-top: 20px;
        }
      }

    }

    .table-label {
      width: 100%;
      margin: 10px 0 5px 0;
      color: $color-gray-dark;

      overflow-x: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;

      @media print, screen and (min-width: $screen-sm-min) {
        width: inherit;
        font-size: 16px;
        text-align: right;
        margin: 0;
        padding-right: 25px;
        flex: 1 0 150px;
      }

    }

    .table-content {
      flex: 8;
    }
  }

  .field-user {
    .user-avatar {
      display: inline-block;
    }

    .user-field-wrapper {
      display: flex;
      align-items: baseline;

      & + .user-field-wrapper {
        margin-top: 10px;
      }
    }

  }
}