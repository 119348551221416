// CSS Properties
// --------------------------------------------------

$images: (
  image-coyo-nav: '/assets/images/logos/coyo/logo-coyo-inversed-nav.png',
  image-coyo-nav-hd: '/assets/images/logos/coyo/logo-coyo-inversed-nav-hd.png',
  image-coyo-front: '/assets/images/logos/coyo/logo-coyo-inversed-front.png',
  image-coyo-front-hd: '/assets/images/logos/coyo/logo-coyo-inversed-front-hd.png',
  image-coyo-app-icon: '/assets/images/logos/coyo/icon-coyo-app.png',
  image-coyo-app-icon-hd: '/assets/images/logos/coyo/icon-coyo-app-hd.png',
);

$colors: (
  btn-primary-bg: #0073e6,
  btn-primary-color: #ffffff,
  color-background-main: #e8e8e8,
  color-navbar-border: #697687,
  color-primary: #101d30,
  color-secondary: #101d30,
  coyo-navbar-text: #ffffff,
  link-color: #0073e6,
  text-color: #101d30,
  color-yellow: #f0ad4e,
  color-white: #ffffff,
  color-black: #333,
  color-blue: #317dc1,
  color-green: #9bbf29,
  color-red: #d0021b,
  color-orange: #dd8926,
  color-gray: #aaaaaa,
  color-gray-lighter: #f3f3f3,
  color-gray-light: #dadada,
  color-gray-dark: #999,
  color-gray-darker: #666,
  color-form-error: #a94442,
  color-form-correct: #9bbf29,
  color-link: #0073e6,
  color-original: #101d30
);

:root {
  --font-family-base: "Source Sans Pro", sans-serif;
  --height-image-front: 80px;
  --width-navbar-brand: 125px;

  @each $name, $value in $images {
    --#{$name}: url(#{$value});
  }

  @each $name, $value in $colors {
    --#{$name}: #{$value};
    --#{$name}-h: #{strip-units(hue($value))};
    --#{$name}-s: #{saturation($value)};
    --#{$name}-l: #{lightness($value)};
  }

  --coyo-navbar: var(--color-primary);
  --coyo-navbar-h: var(--color-primary-h);
  --coyo-navbar-s: var(--color-primary-s);
  --coyo-navbar-l: var(--color-primary-l);

  --coyo-navbar-active: hsla(0, 0%, 0%, 0.3);
  --coyo-navbar-active-h: 0;
  --coyo-navbar-active-s: 0%;
  --coyo-navbar-active-l: 0%;
}
