@import "variables";

// setting overflow visible is needed for widget layout advanced mode
.blog-article-settings {
  .mat-tab-body-wrapper {
    overflow: visible;
    .mat-tab-body {
      overflow: visible;
      width: 100%;
      .mat-tab-body-content {
        overflow: visible;
      }
    }
  }
}

.date-input-group {
  display: flex;
  position: relative;
  .date {
    width: 65%;
    > input {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }
  }
  .time {
    width: 35%;
    > input {
      text-align: center;
      border-radius: 0 $border-radius-base $border-radius-base 0;
    }
  }

  & mat-datepicker-toggle {
    top: 0;
  }
}

.cui-input-info-right {
  flex-grow: 1;
}
