.workspace-members {
  display: flex;

  .user-avatar, .workspace-member-count {
    margin-left: -15px;

    @media print, screen and (min-width: $screen-md-min) {
      margin-left: -12px;
    }
  }

  .user-avatar .user-avatar-empty,
  .workspace-member-count,
  .user-avatar .user-avatar-image img {
    box-shadow: 0 0 0 2px $color-white;
  }
}

.workspace-member-count {
  align-items: center;
  background-color: $n400;
  border-radius: 50%;
  color: $n0;
  display: flex;
  height: 30px;
  justify-content: center;
  position: relative;
  width: 30px;

  span {
    color: $color-white;
  }
}

