.nav-search {
  .no-border:focus {
    box-shadow: none !important;
  }

  .dropdown-menu {
    @include coyo-navbar-dropdown-padding;
    left: 7px;
    width: 100%;
    overflow: hidden;

    border-radius: 4px;
    box-shadow: $coyo-navbar-popup-shadow;
    background-color: $color-white;
    border: solid 1px $input-inplace-edit-border;

    @media screen and (max-width: $screen-sm) {
      left: 0;
      position: fixed;
      margin-top: 8px;
      bottom: 0;
      overflow: scroll;
    }

    li:hover,
    li.active {
      background-color: $color-gray-lighter;
      cursor: pointer;
    }
  }

  .search-bar-quick-result {
    padding: 8px 16px;

    &.show-all-results-dummy {
      color: $color-link;
      font-size: 14px;
      line-height: 20px;
      border-bottom: solid 1px $input-inplace-edit-border;
      padding: 13px 16px;

      .query {
        font-weight: bold;
      }

      .zmdi-search {
        font-size: 18px;
        padding-right: 6px;
      }
    }
    > div {
      display: flex;
    }

    .sender-avatar-empty {
      background-color: $color-gray-light;
    }

    .right-side {
      padding-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $color-gray-dark;
      height: 40px;
      line-height: 20px;
    }

    .name {
      color: $color-black;
      font-size: 15px;
      em {
        background-color: $color-text-highlight-bg;
        border-radius: 2px;
        font-style: normal;
      }
    }

    .subtitle {
      font-size: 14px;
    }

    .subtitle em {
      background-color: $color-text-highlight-bg;
      border-radius: 2px;
      font-style: normal;
    }
  }
}
