/* Selects
------------------------------------------------------------ */

.ui-select-bootstrap .ui-select-no-choice > li {
  clear: both;
  color: #dadada;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 8px 16px;
  white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row > a {
  padding: 8px 16px;
}

.ui-select-multiple.ui-select-bootstrap {
  min-height: 42px;
  padding-top: 1px;

  input.ui-select-search {
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding-left: 13px;
  }

  .ui-select-match:not(:empty) + input.ui-select-search {
    padding-left: 8px;
  }

  .ui-select-match .close {
    font-size: 18px;
    line-height: 24px;
    margin-left: 2px;
  }

  .ui-select-match-item {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
  }
}

.ui-select-bootstrap .ui-select-choices-row {

  small {
    display: block;
    line-height: 20px;
  }

  &.active small {
    color: #fff;
  }
}

.ui-select-mobile-list {
  color: $color-gray-darker;

  li {
    display: block;
    padding: 10px 3px;

    i:first-child {
      margin-right: 10px;
    }
  }
}