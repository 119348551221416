.widget-image {
  coyo-internal-image {
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      border-radius: 5px;
      max-width: 100%;
      min-width: 100%;
    }
  }
}

/* Cover special cases for image-widget display */
.content-sidebar {
  .widget-slot-sidebar {
    // Order is important for correct rendering according to CSS rules
    .widget {
      &~.widget {
        .widget-image {
          coyo-internal-image {
            img {
              border-radius: 0;
            }
          }
        }
      }

      &:first-child {
        .widget-image {
          coyo-internal-image {
            img {
              border-radius: 5px 5px 0 0;
            }
          }
        }
      }

      &:last-child {
        .widget-image {
          coyo-internal-image {
            img {
              border-radius: 0 0 5px 5px;
            }
          }
        }
      }

      &:only-child {
        .widget-image {
          coyo-internal-image {
            img {
              border-radius: 5px;
            }
          }
        }
      }

      .widget-image {
        margin-bottom: auto;
      }
    }
  }
}
