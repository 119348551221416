.panel-list-app {

  .panel-body {
    position: relative;

    @include xs() {
      padding-left: 0;
      padding-right: 0;
    }

    .app-entry-form {
      margin-top: 22px;
    }
  }

  .panel-heading {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    border: 0;

    .panel-heading-item,
    .context-menu-toggle {
      padding: 12px;
      border-left: 1px solid $color-gray-light;
    }
    .context-menu-toggle {
      display: inline-block;
    }
    .search-wrapper.panel-heading-item,
    coyo-page-entry-count.panel-heading-item {
      border:none;
    }

    &.panel-heading-configure {
      justify-content: space-between;
    }
  }

  h3.list-element-name {
    border-bottom: solid $color-gray-light 1px;
    margin-top: 10px;
    padding-bottom: 10px;

    @media print, screen and (min-width: $screen-sm-min) {
      margin-top: 0;
    }

    @include xs() {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .pagination {
    margin-bottom: 0;
  }
}

.field-checkbox {
  .zmdi-check {
    color: $color-green;
  }
  .zmdi-close {
    color: $color-red;
  }
  .inline-edit {
    cursor: pointer;
  }
}

.field-user {
  .user-avatar {
    display: none;

    @media print, screen and (min-width: $screen-sm-min) {
      display: block;
    }
  }

  .user-field-wrapper {
    display: flex;
    align-items: center;
    @include break-word();

    & +.user-field-wrapper {
      margin-top: 10px;
    }

    a {
      @media (-ms-high-contrast: active), (-ms-high-contrast: none) { // only use this in IE, breaks width in Edge
        flex: 1 1 1px;
      }
      min-width: 0px; // required for Edge so column does not overflow
    }
  }

}

.field-options {
  .option-field-item {

    @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: inline-block;
    }

    width: 100%;
    display: flex;
    @include break-word();
  }
}

.field-text {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.field-link {
  @include break-word();
  min-width: unset;


  .tooltip {
    animation-name: zoomIn;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
    opacity: 0;
    line-break: auto;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    word-wrap: break-word;
    word-break: break-word;
    border-radius: var(--cui-border-radius-m, 0.25rem);
    background-color: rgba(16, 29, 48, 0.85);
    color: #ffffff;
  }

  .tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px;
  }

  .tooltip.in {
    opacity: 1;
  }

  .fade:not(.fade.in) {
    animation-name: zoomout;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
  }

  @keyframes zoomIn {
    from { transform: scale(0, 0); }
    to   { transform: scale(1, 1); }
  }

  @keyframes zoomout {
    from { transform: scale(1, 1); }
    to   { transform: scale(0, 0); }
  }

  .tooltip-inner {
    transition-duration: 300ms;
    transition-property: transform, visibility, opacity;
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
  }
}

.modal-body-field-type {
  padding: 0;

  @media print, screen and (min-width: $screen-sm-min) {
    padding: 16px 24px;
  }
}

.option-field-settings {
  .option-field-option {
    margin-bottom: 10px;
  }
}

.option-field-settings, .list-app-fields {
  .angular-ui-tree-placeholder {
    background: $color-gray-lighter;
    border: 1px dashed $color-gray;
  }
}

.angular-ui-tree-drag .list-app-field {
  .field-handle, .field-options {
    visibility: visible;
  }
}

.list-settings-show-advanced-handle {
  cursor: pointer;
}
