.password-reset {

  .btn {
    @include ellipsis();
  }

  .buttons-right {
    display: flex;
    justify-content: flex-end;

    .btn-primary {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    .buttons-right {
      flex-direction: column-reverse;
      justify-content: stretch;

      .btn-primary {
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }

}
