@mixin panel() {
  border: 0;

  .panel-heading {
    border-color: $color-border;
    margin: 0 30px;
    padding-left: 0;
    padding-right: 0;

    .btn {
      margin-left: 8px;
    }
  }

  .panel-actions {
    border-top: 1px solid $color-gray-lighter;
    padding-top: 24px;
    padding-bottom: 24px;

    .btn {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .panel-title {
    font-size: $font-size-h2;
    line-height: floor($font-size-h2 * $headings-line-height);

    padding: 0;
    margin: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    small {
      color: $color-gray;
      font-size: percentage(1*0.5);
      text-transform: uppercase;
      font-weight: normal;
      margin-left: 0.75rem;
    }

    .zmdi {
      font-size: 90%;
      margin-right: 0.6rem;
      margin-left: 1px; // fix browser UI bug
    }
  }
}

@mixin panel-xs() {
  .panel-heading {
    margin: $panel-xs-heading-margin;
    padding: $panel-xs-heading-padding;

    div:not(.context-menu) {
      &.btn:not(.ui-select-toggle,.user-external) {
        @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
        line-height: 18px;
        margin-left: 4px;
      }
    }
  }

  .panel-title {
    font-size: $font-size-h4;
    font-weight: bold;
    line-height: floor($font-size-h4 * $headings-line-height);

    small {
      font-size: percentage(3*0.25);
    }

    .zmdi {
      font-size: 100%;
    }
  }

  .panel-body {
    padding: $panel-xs-body-padding;
  }

  .panel-footer {
    padding: $panel-xs-footer-padding;
  }
}

@mixin panel-sm() {
  .panel-heading {
    margin: $panel-sm-heading-margin;
    padding: $panel-sm-heading-padding;

    .btn {
      margin-left: 6px;
    }
  }

  .panel-title {
    font-size: $font-size-h3;
    line-height: floor($font-size-h3 * $headings-line-height);
  }

  .panel-body {
    padding: $panel-sm-body-padding;
  }

  .panel-footer {
    padding: $panel-sm-footer-padding;
  }
}

// Actual panel styles
.panel {
  @include panel();
}

.panel-sm {
  @include panel-sm();
}

.panel-xs {
  @include panel-xs();
}

@include sm-and-down {
  .panel {
    border-radius: 0;
    margin-bottom: 12px;
  }
}

@include xs {
  .panel {
    @include panel-xs();

    .panel-actions {
      padding-top: 16px;
      padding-bottom: 16px;

      .btn {
        display: block;
        width: 100%;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.panel {
  .panel-nav {
    background-color: $gray-lighter;
    padding: $panel-xs-nav-padding;

    @include sm() {
      padding: $panel-sm-nav-padding;
    }
    @include md-and-up() {
      border-radius: $panel-border-radius $panel-border-radius 0 0;
      padding: $panel-nav-padding;
    }
  }

  .panel-full-width {
    margin-left: -1 * nth($panel-body-padding, 2);
    margin-right: -1 * nth($panel-body-padding, 2);
  }
}
