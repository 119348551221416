.user-card-external {

  .user-card-heading {
    display: flex;
    position: relative;

    coyo-user-avatar {
      flex: none;
      align-self: center;
    }

    .user-card-heading-text {
      flex: 1;
      padding-left: 18px;
      min-width: 0;
      align-self: center;

      .user-card-subtitle {
        color: $color-gray;
      }

      .user-card-title, .user-card-subtitle {
        font-weight: normal;
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @media screen and (max-width: $screen-sm-max) {
        .user-card-title {
          font-size: 16px;
        }
      }
    }
  }

  .panel-body {
    @media screen and (min-width: $screen-md-min) {
      border-top: 1px solid $color-gray-lighter;
    }

    dd {
      margin-bottom: 8px;
      min-height: 24px;
      float: left;

      &:last-child {
        margin-bottom: 0;
      }
    }

    dl {
      margin-bottom: -4px;
    }
  }
}
