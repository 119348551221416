// Global
// --------------------------------------------------

html {
  height: 100%;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @media screen and (min-width: $screen-md-min) {
    height: auto;
  }

  &.fixed {
    // prevent page "overscrolling" in webview if sidebar is open
    overflow-y: hidden;
  }
}

body {
  background-color: $color-background-main;
  color: $text-color;
}

html, body, .body {
  min-height: 100vh;
  margin: 0;
  width: 100%;
  font-size: 16px; //this should be deleted, but BS resets the root font size to 10px :-(
}

body, .body {
  font-size: 15px;
  height: auto;

  /**
   * BUGFIX for page jumping on mobile safari when search field get focused
   * mobile Safari has a bug when input in fixed div getting focus
   * ! don´t work when <html> miss height="100%"
   * ! If html not equal to 100% and the fix is used, the content is cut off in mobile Safari when the mobile navigation is open
   */
  overflow-x: hidden;
  overflow-y: auto;
}

body.backdrop-visible,
body.backdrop-visible .body {
  overflow: hidden;
  position: static;
}

.body {
  position: inherit;
}

a {
  cursor: pointer;
}

a.no-link {
  cursor: default;
  color: $text-color;
}

[ng\:cloak], [ng-cloak], [ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  // scss-lint:disable ImportantRule
  display: none !important;
  // scss-lint:enable ImportantRule
}
