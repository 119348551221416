.datetime-picker-dropdown {
  box-shadow: none;
  margin: 0;
  padding: 8px;
  z-index: 1050;

  table {
    margin: 0;
    outline: none;
    width: 100%;

    .btn-sm {
      box-shadow: none;
      font-size: 14px;

      &:hover {
        box-shadow: none;
      }
    }

    .caret {
      margin-left: .4em;
      margin-top: -.1em;
    }

    th button {
      width: 100%;
    }

    button {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      color: $text-color;
      display: block;
      padding: 6px 8px;

      i {
        font-weight: 600;
        padding: 0;
      }

      &:active,
      &:focus,
      &:active:focus,
      &:hover,
      &:active:hover,
      &.btn-default[disabled]:hover,
      &.btn-default[disabled]:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: inherit;
        outline: none;
      }

      &.current {
        background-color: $gray-lighter;

        &:hover, &:focus {
          background-color: $gray-lighter;
          border-color: transparent;
          outline: none;
        }

        * {
          color: $btn-default-color;
        }
      }

      &.active {
        background-color: $btn-primary-bg;
        border-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus {
          background-color: $btn-primary-bg;
          border-color: transparent;
          outline: none;
        }

        * {
          color: $btn-primary-color;
        }
      }
    }
  }

  .uib-timepicker {
    .btn {
      color: $text-color;
      font-size: 18px;
      line-height: 18px;
      outline: none;
      padding: 6px 12px;
    }

    input {
      margin-left: 8px;
      width: 55px;
    }
  }
}