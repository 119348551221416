@import "variables";

@font-face {
  font-family: 'Coyo4_File_Types';
  src: url($font-coyo-woff) format('woff'),
  url($font-coyo-ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.zmdi.zmdi-coyo {
  font-family: 'Coyo4_File_Types';

  &.zmdi-coyo-image {
    &:before {
      content: '\e901';
    }
  }
}
