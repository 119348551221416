.page-imprint {
  .imprint-heading {
    display: flex;
    border: none;

    h3 {
      font-weight: normal;
      font-size: 20px;

      @include sm-and-up() {
        font-size: 24px;
      }
    }

    span {
      color: $color-gray;
      margin-left: 10px;
      padding-top: 5px;
      font-size: 12px;

      @include sm-and-up() {
        font-size: inherit;
        padding-top: 11px;
      }
    }

  }
  .imprint-edit-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $color-gray-lighter;
    height: 42px;

    .right {
      border-left: 1px solid #DADADA;
      border-top: none;
      border-bottom: none;
    }

    .edit-header {
      align-items: center;
      display: flex;
      height: 42px;
      justify-content: space-between;

      .btn-primary {
        border-top-right-radius: 0px;
        justify-content: space-between;

        @include sm-and-up() {
          border-top-right-radius: 5px;
        }
      }

      .btn-secondary {
        a {
          color: $n200;
        }
      }

      i {
        margin-right: 10px;
      }

    }
  }
  .panel-body {
    .page-imprint-admins {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -20px;

      @include sm-and-up() {
        margin-bottom: -30px;
      }

      @include sm-and-up() {
        flex-direction: row;
      }

      &:after {
        content: "";
        flex: auto;
      }

      .admin-panel {
        display: flex;
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        padding-right: 8px;

        @include sm-and-up() {
          width: 33.333333333%;
        }

        @include sm-and-up() {
          margin-bottom: 30px;
        }

        .admin-name-job-title {
          margin-left: 15px;
          width: 150px;
          .name {
            font-size: 17px;
            margin-bottom: 0;
            margin-top: 0;
          }
          .job-title {
            color: $color-gray;
            font-size: 12px;
          }
        }
      }
    }

    .widget-layout.widget-slot-plain .widget-layout-row:last-child .widget-slot  {
      margin-bottom: 0;
    }
  }
}


