@import "variables";

.filter-box-actions-menu-overlay {
  fb-actions > button.btn {
    color: $text-color;
    background-color: $color-white;
    margin-bottom: 1rem;
    border-radius: $coyo-border-radius-md;
    // match the size of the material buttons
    padding: 11px 24px;
  }
  .coyo-event-create-menu {
    > button {
      width: 100%;
    }
  }
}

.filter-box-compact-filter-menu {
  > .mat-menu-content:not(:empty) {
    padding: 4px 0;
  }
}

.filter-box {
  fb-filter .fb-filter-action.btn {
    border-top: 1px solid $color-gray-light;
    padding: 10px 20px;
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: -16px;
    width: 100%;
  }
  fb-search {
   width: 100%;
  }
}
