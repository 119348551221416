.translationsForm {
  .modal-content-wrapper {
    .modal-header {
      z-index: 1;
      .modal-app-settings.ui-select-translation {
        right: 25px !important;

        @media screen and (max-width: 991px) {
          right: 50px !important;
        }

        @media screen and (max-width: 767px) {
          right: 8px !important;
          top: 60px !important;
          position: absolute;
        }
      }
      @media screen and (max-width: 767px) {
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, #101d30, #101d30 50%, transparent 50%, transparent 100%);
        background-image: -webkit-linear-gradient(to bottom, #101d30, #101d30 50%, transparent 50%, transparent 100%);
        min-height: 92px !important;
      }

      h1 {
        &.modal-title {
          font-size: $font-size-h3;
          line-height: $line-height-base;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 24px;
        }
      }

    }

    .modal-body {
      @media screen and (max-width: $screen-md-min) {
        .app-group-section {
          .app-group-name {
            padding-right: 8px !important;
            width: 100%;
            input {
              float: right;
              width: 100%;
            }
          }
        }
        .app-section {
          .app-name {
            padding-right: 8px !important;
            width: 100%;
            input {
              margin-left: 0 !important;
              float: right;
              width: 90%;
            }
            i {
              right: 5px !important;
            }
          }
          label {
            padding-left: 7%;
          }
        }
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
        .app-group-section {
          padding-right: 20px;
          label {
            width: auto;
            float: left;
          }
        }
        .app-section {
          label {
            width: auto;
            float: left;
          }
          .app-name {
            input {
              margin-left: 0 !important;
            }
          }
        }
      }
      .app-group-section:not(:last-child) {
        border-bottom: 1px solid rgba(51, 51, 51, 0.15);
        margin-bottom: 25px;
      }
      .app-group-section {
        .app-group-name {
          padding-right: 40px;
        }
      }
      .app-section {
        margin-top: 20px;
        margin-bottom: 15px;
        .app-name {
          float: right;
          padding-right: 35px;
          input {
            margin-left: 30px;
            width: 95%;
          }
        }
        .form-group {
          margin-bottom: 10px !important;
          @media screen and (max-width: $screen-md-min) {
            .zmdi {
              &.app-icon {
                display: inline-block !important;
                color: #7D93B2 !important;
                z-index: 1 !important;
                top: 14px;
                position: relative;
                float: left;
              }
            }
          }
          @media print, screen and (min-width: $screen-md-min) {
            .zmdi {
              &.app-icon {
                display: inline-block !important;
                color: #7D93B2 !important;
                z-index: 1 !important;
                top: 14px;
                position: relative;
                float: left;
              }
            }
          }
        }
      }
    }
  }
}
