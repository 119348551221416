.backdrop {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .4s;
  z-index: $zindex-sidebar;

  &.fading {
    opacity: .5;
  }
}
