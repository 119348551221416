.launchpad {
  .launchpad-body-wrapper {
    pointer-events: auto;
    -webkit-overflow-scrolling: touch;
  }

  .launchpad-body {
    margin: 0 auto;
    padding: 16px 20px 20px;

    @include sm-and-up() {
      padding: 24px 40px 64px;
    }
  }
}
