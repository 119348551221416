// Mixins - Unread bubble
// --------------------------------------------------

// example usage:
// @include unread-bubble()
@mixin unread-bubble() {
  display: inline-block;
  background-color: $color-red;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: $color-white;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  font-family: $font-family-base;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  @include animate(pulse 0.3s);
}
