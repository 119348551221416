.container-admin {
  background-color: $color-background-main;
  flex: 1;
  max-width: 100%;
  display: flex;

  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 30px;
  }

  .content-wrapper {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-flow: row;
    /* FIX height bug in IE and Safari */
    min-height: 100%;
    height: auto !important;
    height: 100%;

    .content {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;

      padding-top: 62px;
      padding-left: 6px;
      padding-right: 6px;

      @media print, screen and (min-width: $screen-md-min) {
        padding: 24px 48px 100px;
      }

      @media print, screen and (min-width: $screen-lg-min) {
        padding-top: 24px;
      }
    }
  }
}
