@import "variables";
@import "mixins";

// --- Notifications

coyo-notification-center {
  .tabs-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: cui-border-radius(m);
    border-bottom-right-radius: cui-border-radius(m);
  }
}

coyo-notification-item {
  .message > p:last-child {
    margin-bottom: 0;
  }
}
