/**
 *  COLLEAGUES
 */

$skeleton-colleague-card-container-height: 1785px; // should be multiple of $skeleton-colleague-card-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-colleague-card-height: 255px;            // height of the event list-entry skeleton

.skeleton-container-colleague-card {
  width: calc(100%);
  height: $skeleton-colleague-card-container-height;
  padding: 20px;

  background-image:
          linear-gradient( $color-white 94px, transparent 0 ),
          linear-gradient( $gray-lighter 1px, transparent 0 ),
          linear-gradient( $color-white 135px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          100% $skeleton-colleague-card-height,
          100% $skeleton-colleague-card-height,
          100% $skeleton-colleague-card-height;

  background-position:
          0 0,
          0 94px,
          0 95px;
}

.skeleton-colleague-card:empty {
  width: 100%;
  height: $skeleton-colleague-card-container-height;

  background-image:
          radial-gradient( circle 30px at 35px 30px, $gray-lighter 99%, transparent 0 ), /* avatar */
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 25px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          70px $skeleton-colleague-card-height,
          50px calc(#{$skeleton-colleague-card-height} - 65px), /* highlight */
          120px $skeleton-colleague-card-height,
          60px $skeleton-colleague-card-height,
          50px $skeleton-colleague-card-height,
          65px $skeleton-colleague-card-height,
          100px $skeleton-colleague-card-height,
          180px $skeleton-colleague-card-height,
          120px $skeleton-colleague-card-height,
          75px $skeleton-colleague-card-height;

  background-position:
          0 0,
          0 0, /* highlight */
          85px 10px,
          85px 40px,
          70px 100px,
          55px 135px,
          20px 170px,
          140px 100px,
          140px 135px,
          140px 170px;

  animation: shine-colleague-card 1.25s infinite;
}

@keyframes shine-colleague-card {
  to {
    background-position:
            0 0,
            100% 0, /* move highlight to right */
            85px 10px,
            85px 40px,
            70px 100px,
            55px 135px,
            20px 170px,
            140px 100px,
            140px 135px,
            140px 170px;
  }
}

$skeleton-colleague-list-entry-container-height: 1840px; // should be multiple of $skeleton-colleague-list-entry-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-colleague-list-entry-height: 92px;            // height of the event list-entry skeleton

.skeleton-container-colleague-list-entry {
  width: calc(100%);
  height: $skeleton-colleague-list-entry-container-height;
  padding: 20px;

  background-image: linear-gradient( $color-white 80px, transparent 0 );
  background-repeat: repeat-y;
  background-size: 100% $skeleton-colleague-list-entry-height;
  background-position: 0 0;
}

.skeleton-colleague-list-entry:empty {
  width: 100%;
  height: $skeleton-colleague-list-entry-container-height;

  background-image:
          radial-gradient( circle 22px at 25px 20px, $gray-lighter 99%, transparent 0 ), /* avatar */
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 25px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          70px $skeleton-colleague-list-entry-height,
          50px $skeleton-colleague-list-entry-height, /* highlight */
          120px $skeleton-colleague-list-entry-height,
          60px $skeleton-colleague-list-entry-height;

  background-position:
          0 0,
          0 0, /* highlight */
          60px 0,
          60px 30px;

  animation: shine-colleague-list-entry 1.25s infinite;
}

@keyframes shine-colleague-list-entry {
  to {
    background-position:
            0 0,
            100% 0, /* move highlight to right */
            60px 0,
            60px 30px;
  }
}


/**
 *  EVENTS
 */

$skeleton-event-card-container-height: 1520px; // should be multiple of $skeleton-event-card-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-event-card-height: 190px;            // height of the event card skeleton

.skeleton-container-event-card {
  width: calc(100%);
  height: $skeleton-event-card-container-height;
  margin: 0 20px;
  padding: 20px;

  background-image:
          linear-gradient( $gray-light 160px, transparent 0 ),
          linear-gradient( $color-white calc(#{$skeleton-event-card-height} - 30px), transparent 0 );

  background-repeat: repeat-y;

  background-size:
          160px $skeleton-event-card-height,
          100% $skeleton-event-card-height;

  background-position:
          0 0,
          0 0;
}

.skeleton-event-date-heading:empty {
  height: 20px;
  margin: 22.5px;
  background-image: linear-gradient( $gray-light 20px, transparent 0 );
  background-repeat: repeat-y;
  background-size: 25% $skeleton-event-card-height;
  background-position: 50% 0;
}


.skeleton-event-card:empty {
  width: 100%;
  height: $skeleton-event-card-container-height;

  background-image:
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          50px calc(#{$skeleton-event-card-height} - 45px), /* highlight */
          100px $skeleton-event-card-height,
          120px $skeleton-event-card-height,
          80% $skeleton-event-card-height,
          70% $skeleton-event-card-height,
          5% $skeleton-event-card-height,
          15% $skeleton-event-card-height,
          15% $skeleton-event-card-height,
          5% $skeleton-event-card-height;

  background-position:
          20% 0, /* highlight */
          100% 0,
          160px 0,
          160px 40px,
          160px 70px,
          160px 105px,
          calc(160px + 10%) 105px,
          calc(160px + 30%) 105px,
          calc(160px + 45%) 105px;

  animation: shine-event-card 1.25s infinite;
}

@keyframes shine-event-card {
  to {
    background-position:
            100% 0, /* move highlight to right */
            100% 0,
            160px 0,
            160px 40px,
            160px 70px,
            160px 105px,
            calc(160px + 10%) 105px,
            calc(160px + 30%) 105px,
            calc(160px + 45%) 105px;
  }
}

$skeleton-event-list-entry-container-height: 1860px; // should be multiple of $skeleton-event-list-entry-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-event-list-entry-height: 110px;            // height of the event list-entry skeleton

.skeleton-container-event-list-entry {
  width: calc(100%);
  height: $skeleton-event-list-entry-container-height;
  margin: 0 10px;
  padding: 15px;

  background-image:
          linear-gradient( $gray-light 80px, transparent 0 ),
          linear-gradient( $gray-light 80px, transparent 0 ),
          linear-gradient( $color-white calc(#{$skeleton-event-list-entry-height} - 30px), transparent 0 );

  background-repeat: repeat-y;

  background-size:
          80px $skeleton-event-list-entry-height,
          1px $skeleton-event-list-entry-height,
          100% $skeleton-event-list-entry-height;

  background-position:
          0 0,
          calc(100% - 70px) 0,
          0 0;
}

.skeleton-event-list-entry:empty {
  width: 100%;
  height: $skeleton-event-list-entry-container-height;

  background-image:
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          radial-gradient( circle 12px at 30px 25px, $gray-lighter 99%, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          50px calc(#{$skeleton-event-list-entry-height} - 95px), /* highlight */
          50px $skeleton-event-list-entry-height,
          120px $skeleton-event-list-entry-height,
          25px $skeleton-event-list-entry-height,
          75px $skeleton-event-list-entry-height,
          25px $skeleton-event-list-entry-height;

  background-position:
          0 0, /* highlight */
          100% 0,
          80px 0,
          80px 35px,
          115px 35px,
          200px 35px,
          320px 35px;

  animation: shine-event-list-entry 1.25s infinite;
}

@keyframes shine-event-list-entry {
  to {
    background-position:
            100% 0, /* move highlight to right */
            100% 0,
            80px 0,
            80px 35px,
            115px 35px,
            200px 35px,
            320px 35px;
  }
}


/**
 *  PAGES
 */

$skeleton-page-card-container-height: 1920px; // should be multiple of $skeleton-page-card-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-page-card-height: 320px;            // height of the page card skeleton

.skeleton-container-page-card {
  width: calc(100%);
  height: $skeleton-page-card-container-height;
  padding: 20px;

  background-image:
          linear-gradient( $gray-lighter 60px, transparent 0 ),
          linear-gradient( $color-white calc(#{$skeleton-page-card-height} - 90px), transparent 0 );

  background-repeat: repeat-y;

  background-size:
          100% $skeleton-page-card-height,
          100% $skeleton-page-card-height;

  background-position:
          0 0,
          0 60px;
}

.skeleton-page-card:empty {
  width: 100%;
  height: $skeleton-page-card-container-height;

  background-image:
          linear-gradient( $gray-light 80px, transparent 0 ),
          linear-gradient( $gray-light 30px, transparent 0 ),
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          80px $skeleton-page-card-height,
          100px $skeleton-page-card-height,
          50px calc(#{$skeleton-page-card-height} - 65px), /* highlight */
          20% $skeleton-page-card-height,
          15% $skeleton-page-card-height,
          10% $skeleton-page-card-height,
          20% $skeleton-page-card-height;

  background-position:
          0 0,
          95% 20px,
          0 0, /* highlight */
          0 100px,
          0 125px,
          0 235px,
          15% 235px;

  animation: shine-page-card 1.25s infinite;
}

@keyframes shine-page-card {
  to {
    background-position:
            0 0,
            95% 20px,
            100% 0, /* move highlight to right */
            0 100px,
            0 125px,
            0 235px,
            15% 235px;
  }
}


/**
 *  WORKSPACES
 */

$skeleton-workspace-card-container-height: 1780px; // should be multiple of $skeleton-workspace-card-height, e.g. with 285px then 1710px would render six skeletons
$skeleton-workspace-card-height: 220px;            // height of the workspace card skeleton

.skeleton-container-workspace-card {
  width: calc(100%);
  height: $skeleton-workspace-card-container-height;
  padding: 20px;

  background-image: linear-gradient( $color-white calc(#{$skeleton-workspace-card-height} - 30px), transparent 0 );
  background-repeat: repeat-y;
  background-size: 100% $skeleton-workspace-card-height;
  background-position: 0 0;
}

.skeleton-workspace-card:empty {
  width: 100%;
  height: $skeleton-workspace-card-container-height;

  background-image:
          linear-gradient( $gray-light 80px, transparent 0 ),
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          80px $skeleton-workspace-card-height,
          50px calc(#{$skeleton-workspace-card-height} - 65px), /* highlight */
          120px $skeleton-workspace-card-height,
          60px $skeleton-workspace-card-height,
          80% $skeleton-workspace-card-height,
          70% $skeleton-workspace-card-height,
          7.5% $skeleton-workspace-card-height,
          12.5% $skeleton-workspace-card-height,
          25% $skeleton-workspace-card-height;

  background-position:
          0 0,
          0 0, /* highlight */
          100px 10px,
          100px 40px,
          100px 70px,
          100px 100px,
          0 135px,
          10% 135px,
          100% 135px;

  animation: shine-workspace-card 1.25s infinite;
}

@keyframes shine-workspace-card {
  to {
    background-position:
            0 0,
            100% 0, /* move highlight to right */
            100px 10px,
            100px 40px,
            100px 70px,
            100px 100px,
            0 135px,
            10% 135px,
            100% 135px;
  }
}


/**
 *  BIRTHDAY WIDGET
 */

.skeleton-widget-birthday:empty {
  width: 100%;
  margin-left: -30px;

  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
  linear-gradient($gray-lighter 30px, transparent 0),
  radial-gradient(circle 22px at 25px 22px, $gray-lighter 99%, transparent 0), /* avatar */
  linear-gradient($gray-lighter 20px, transparent 0),
  linear-gradient($gray-lighter 15px, transparent 0);

  background-repeat: repeat-y;
}

.col-md-12 {
  $skeleton-widget-birthday-container-height: 375px; // should be multiple of $skeleton-widget-birthday-height
  $skeleton-widget-birthday-height: 75px;            // height of the widget latest files skeleton

  .skeleton-widget-birthday:empty {
    height: $skeleton-widget-birthday-container-height;
  }

  .skeleton-widget-birthday:empty {
    background-size: 50px $skeleton-widget-birthday-height, /* highlight */
    120px $skeleton-widget-birthday-height,
    50px $skeleton-widget-birthday-height,
    80px $skeleton-widget-birthday-height,
    105px $skeleton-widget-birthday-height;

    background-position: 0 0, /* highlight */
    0 0,
    145px 90px,
    205px 95px,
    205px 120px;

    animation: shine-widget-birthday-md-12 1.25s infinite;
  }

  @keyframes shine-widget-birthday-md-12 {
    to {
      background-position: 300px 0, /* move highlight to right */
      0 0,
      145px 90px,
      205px 95px,
      205px 120px;
    }
  }
}

.col-md-6,
.col-md-4,
.col-md-3 {
  $skeleton-widget-birthday-container-height: 500px; // should be multiple of $skeleton-widget-birthday-height
  $skeleton-widget-birthday-height: 100px;            // height of the widget latest files skeleton

  .skeleton-widget-birthday:empty {
    height: $skeleton-widget-birthday-container-height;
  }

  .skeleton-widget-birthday:empty {
    background-size: 50px $skeleton-widget-birthday-height, /* highlight */
    120px $skeleton-widget-birthday-height,
    50px $skeleton-widget-birthday-height,
    80px $skeleton-widget-birthday-height,
    105px $skeleton-widget-birthday-height;

    background-position: 0 0, /* highlight */
    0 0,
    25px 40px,
    90px 45px,
    90px 70px;

    animation: shine-widget-birthday-md-6 1.25s infinite;
  }

  @keyframes shine-widget-birthday-md-6 {
    to {
      background-position: 300px 0, /* move highlight to right */
      0 0,
      25px 40px,
      90px 45px,
      90px 70px;
    }
  }
}

/**
 *  SINGLE BLOG ARTICLE WIDGET
 */

$skeleton-widget-single-blog-article-height: 140px;            // height of the widget single-blog-article skeleton

.skeleton-widget-single-blog-article:empty {
  width: 100%;
  height: $skeleton-widget-single-blog-article-height;

  background-image:
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 );


  background-repeat: no-repeat;

  background-size:
          50px $skeleton-widget-single-blog-article-height, /* highlight */
          20% $skeleton-widget-single-blog-article-height,
          75px $skeleton-widget-single-blog-article-height,
          35% $skeleton-widget-single-blog-article-height,
          100% $skeleton-widget-single-blog-article-height,
          95% $skeleton-widget-single-blog-article-height,
          75px $skeleton-widget-single-blog-article-height;

  background-position:
          0 0, /* highlight */
          0px 0,
          100% 0,
          0px 30px,
          0px 70px,
          0px 95px,
          0px 125px;

  animation: shine-widget-single-blog-article 1.25s infinite;
}

@keyframes shine-widget-single-blog-article {
  to {
    background-position:
            100% 0, /* move highlight to right */
            0px 0,
            100% 0,
            0px 30px,
            0px 70px,
            0px 95px,
            0px 125px;
  }
}


/**
 *  LATEST BLOG ARTICLES WIDGET
 */

.col-md-12,
.col-md-6,
.col-md-4 {
  $skeleton-widget-latest-blog-articles-container-height: 330px;
  $skeleton-widget-latest-blog-articles-height: 70px;

  .skeleton-container-widget-latest-blog-articles {
    width: calc(100%);
    height: $skeleton-widget-latest-blog-articles-container-height;
  }

  .skeleton-widget-latest-blog-articles:empty {
    width: 100%;
    height: $skeleton-widget-latest-blog-articles-container-height;

    background-image:
            linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
            linear-gradient( $gray-lighter 20px, transparent 0 ),
            linear-gradient( $gray-lighter 15px, transparent 0 );

    background-repeat: repeat-y;

    background-size:
            50px $skeleton-widget-latest-blog-articles-height, /* highlight */
            15% $skeleton-widget-latest-blog-articles-height,
            40% $skeleton-widget-latest-blog-articles-height;

    background-position:
            0 0, /* highlight */
            0px 0,
            0px 30px;

    animation: shine-widget-latest-blog-articles-md-12 1.25s infinite;
  }

  @keyframes shine-widget-latest-blog-articles-md-12 {
    to {
      background-position:
              50% 0, /* move highlight to right */
              0px 0,
              0px 30px;
    }
  }
}

.col-md-4 {
  $skeleton-widget-latest-blog-articles-container-height: 330px;
  $skeleton-widget-latest-blog-articles-height: 70px;

  .skeleton-widget-latest-blog-articles:empty {
    background-size:
            50px $skeleton-widget-latest-blog-articles-height, /* highlight */
            35% $skeleton-widget-latest-blog-articles-height,
            75% $skeleton-widget-latest-blog-articles-height;
  }
}


.col-md-3 {
  $skeleton-widget-latest-blog-articles-container-height: 430px;
  $skeleton-widget-latest-blog-articles-height: 90px;

  .skeleton-container-widget-latest-blog-articles {
    width: calc(100%);
    height: $skeleton-widget-latest-blog-articles-container-height;
  }

  .skeleton-widget-latest-blog-articles:empty {
    width: 100%;
    height: $skeleton-widget-latest-blog-articles-container-height;

    background-image:
            linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
            linear-gradient( $gray-lighter 20px, transparent 0 ),
            linear-gradient( $gray-lighter 15px, transparent 0 ),
            linear-gradient( $gray-lighter 15px, transparent 0 );

    background-repeat: repeat-y;

    background-size:
            50px $skeleton-widget-latest-blog-articles-height, /* highlight */
            35% $skeleton-widget-latest-blog-articles-height,
            65% $skeleton-widget-latest-blog-articles-height,
            55% $skeleton-widget-latest-blog-articles-height;

    background-position:
            0 0, /* highlight */
            0px 0,
            0px 25px,
            0px 45px;

    animation: shine-widget-latest-blog-articles-md-3 1.25s infinite;
  }

  @keyframes shine-widget-latest-blog-articles-md-3 {
    to {
      background-position:
              50% 0, /* move highlight to right */
              0px 0,
              0px 25px,
              0px 45px;
    }
  }
}

/**
 *  SINGLE FILE WIDGET
 */

.col-md-12 {
  $skeleton-widget-single-file-height: 150px;

  .skeleton-widget-single-file:empty {
    width: 100%;
    height: $skeleton-widget-single-file-height;

    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
    linear-gradient($gray-lighter $skeleton-widget-single-file-height, transparent 0),
    linear-gradient($gray-lighter 20px, transparent 0),
    linear-gradient($gray-lighter 15px, transparent 0),
    linear-gradient($gray-lighter 15px, transparent 0);

    background-repeat: no-repeat;

    background-size: 50px $skeleton-widget-single-file-height, /* highlight */
    32.5% $skeleton-widget-single-file-height,
    15% $skeleton-widget-single-file-height,
    20% $skeleton-widget-single-file-height,
    17.5% $skeleton-widget-single-file-height;

    background-position: 0 0, /* highlight */
    0 0,
    42.5% 20px,
    45% 55px,
    43.75% 75px;

    animation: shine-widget-single-file-md-12 1.25s infinite;
  }

  @keyframes shine-widget-single-file-md-12 {
    to {
      background-position: 100% 0, /* move highlight to right */
      0 0,
      42.5% 20px,
      45% 55px,
      43.75% 75px;
    }
  }
}


@media print, screen and (max-width: $screen-xs-min) {
  .col-md-12 {
    $skeleton-widget-single-file-height: 350px;

    .skeleton-widget-single-file:empty {
      width: 100%;
      height: $skeleton-widget-single-file-height;

      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
      linear-gradient($gray-lighter 225px, transparent 0),
      linear-gradient($gray-lighter 20px, transparent 0),
      linear-gradient($gray-lighter 15px, transparent 0),
      linear-gradient($gray-lighter 15px, transparent 0);

      background-repeat: no-repeat;

      background-size: 50px $skeleton-widget-single-file-height, /* highlight */
      100% $skeleton-widget-single-file-height,
      35% $skeleton-widget-single-file-height,
      27.5% $skeleton-widget-single-file-height,
      40% $skeleton-widget-single-file-height;

      background-position: 0 0, /* highlight */
      0 0,
      50% 245px,
      50% 280px,
      50% 300px;

      animation: shine-widget-single-file-md-12-mobile 1.25s infinite;
    }

    @keyframes shine-widget-single-file-md-12-mobile {
      to {
        background-position: 100% 0, /* move highlight to right */
        0 0,
        50% 245px,
        50% 280px,
        50% 300px;
      }
    }
  }
}

.col-md-6,
.col-md-4,
.col-md-3 {
  $skeleton-widget-single-file-height: 350px;

  .skeleton-widget-single-file:empty {
    width: 100%;
    height: $skeleton-widget-single-file-height;

    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
    linear-gradient($gray-lighter 225px, transparent 0),
    linear-gradient($gray-lighter 20px, transparent 0),
    linear-gradient($gray-lighter 15px, transparent 0),
    linear-gradient($gray-lighter 15px, transparent 0);

    background-repeat: no-repeat;

    background-size: 50px $skeleton-widget-single-file-height, /* highlight */
    100% $skeleton-widget-single-file-height,
    35% $skeleton-widget-single-file-height,
    27.5% $skeleton-widget-single-file-height,
    40% $skeleton-widget-single-file-height;

    background-position: 0 0, /* highlight */
    0 0,
    50% 245px,
    50% 280px,
    50% 300px;

    animation: shine-widget-single-file-md-6 1.25s infinite;
  }

  @keyframes shine-widget-single-file-md-6 {
    to {
      background-position: 100% 0, /* move highlight to right */
      0 0,
      50% 245px,
      50% 280px,
      50% 300px;
    }
  }
}

.col-md-4 {
  $skeleton-widget-single-file-height: 350px;

  .skeleton-widget-single-file:empty {
    background-size: 50px $skeleton-widget-single-file-height, /* highlight */
    100% $skeleton-widget-single-file-height,
    45% $skeleton-widget-single-file-height,
    37.5% $skeleton-widget-single-file-height,
    50% $skeleton-widget-single-file-height;
  }
}

.col-md-3 {
  $skeleton-widget-single-file-height: 350px;

  .skeleton-widget-single-file:empty {
    background-size: 50px $skeleton-widget-single-file-height, /* highlight */
    100% $skeleton-widget-single-file-height,
    55% $skeleton-widget-single-file-height,
    47.5% $skeleton-widget-single-file-height,
    60% $skeleton-widget-single-file-height;
  }
}


/**
 *  LATEST FILES WIDGET
 */

@media print, screen and (min-width: $screen-md-min) {
  .col-md-12 {
    $skeleton-widget-latest-files-container-height: 205px;
    $skeleton-widget-latest-files-height: 45px;

    .skeleton-widget-latest-files:empty {
      width: 100%;
      height: $skeleton-widget-latest-files-container-height;

      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
      linear-gradient($gray-lighter 20px, transparent 0),
      linear-gradient($gray-lighter 20px, transparent 0),
      linear-gradient($gray-lighter 15px, transparent 0);

      background-repeat: repeat-y;

      background-size: 50px $skeleton-widget-latest-files-height, /* highlight */
      20px $skeleton-widget-latest-files-height,
      30% $skeleton-widget-latest-files-height,
      15% $skeleton-widget-latest-files-height;

      background-position: 0 0, /* highlight */
      0 0,
      30px 0,
      100% 0px;

      animation: shine-widget-latest-files-md-12 1.25s infinite;
    }

    @keyframes shine-widget-latest-files-md-12 {
      to {
        background-position: 100% 0, /* move highlight to right */
        0 0,
        30px 0,
        100% 0px;
      }
    }
  }
}

@media print, screen and (max-width: $screen-sm-max) {
  .col-md-12 {
    $skeleton-widget-latest-files-container-height: 330px;
    $skeleton-widget-latest-files-height: 70px;

    .skeleton-widget-latest-files:empty {
      width: 100%;
      height: $skeleton-widget-latest-files-container-height;

      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
      linear-gradient($gray-lighter 20px, transparent 0),
      linear-gradient($gray-lighter 20px, transparent 0),
      linear-gradient($gray-lighter 15px, transparent 0);

      background-repeat: repeat-y;

      background-size: 50px $skeleton-widget-latest-files-height, /* highlight */
      20px $skeleton-widget-latest-files-height,
      50% $skeleton-widget-latest-files-height,
      20% $skeleton-widget-latest-files-height;

      background-position: 0 0, /* highlight */
      0 0,
      30px 0,
      30px 30px;

      animation: shine-widget-latest-files-md-6 1.25s infinite;
    }

    @keyframes shine-widget-latest-files-md-6 {
      to {
        background-position: 100% 0, /* move highlight to right */
        0 0,
        30px 0,
        30px 30px;
      }
    }
  }
}

.col-md-6,
.col-md-4,
.col-md-3 {
  $skeleton-widget-latest-files-container-height: 330px;
  $skeleton-widget-latest-files-height: 70px;

  .skeleton-widget-latest-files:empty {
    width: 100%;
    height: $skeleton-widget-latest-files-container-height;

    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%), /* highlight */
    linear-gradient($gray-lighter 20px, transparent 0),
    linear-gradient($gray-lighter 20px, transparent 0),
    linear-gradient($gray-lighter 15px, transparent 0);

    background-repeat: repeat-y;

    background-size: 50px $skeleton-widget-latest-files-height, /* highlight */
    20px $skeleton-widget-latest-files-height,
    50% $skeleton-widget-latest-files-height,
    20% $skeleton-widget-latest-files-height;

    background-position: 0 0, /* highlight */
    0 0,
    30px 0,
    30px 30px;

    animation: shine-widget-latest-files-md-6 1.25s infinite;
  }

  @keyframes shine-widget-latest-files-md-6 {
    to {
      background-position: 100% 0, /* move highlight to right */
      0 0,
      30px 0,
      30px 30px;
    }
  }
}

.col-md-4 {
  $skeleton-widget-latest-files-height: 70px;

  .skeleton-widget-latest-files:empty {
    background-size: 50px $skeleton-widget-latest-files-height, /* highlight */
    20px $skeleton-widget-latest-files-height,
    60% $skeleton-widget-latest-files-height,
    35% $skeleton-widget-latest-files-height,
    30% $skeleton-widget-latest-files-height;
  }
}

.col-md-3 {
  $skeleton-widget-latest-files-height: 70px;

  .skeleton-widget-latest-files:empty {
    background-size: 50px $skeleton-widget-latest-files-height, /* highlight */
    20px $skeleton-widget-latest-files-height,
    70% $skeleton-widget-latest-files-height,
    45% $skeleton-widget-latest-files-height,
    40% $skeleton-widget-latest-files-height;
  }
}


/**
 *  SINGLE WIKI ARTICLE WIDGET
 */

$skeleton-widget-single-wiki-article-height: 50px;            // height of the widget single-wiki-article skeleton

.skeleton-widget-single-wiki-article:empty {
  width: 100%;
  height: $skeleton-widget-single-wiki-article-height;

  background-image:
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 );


  background-repeat: no-repeat;

  background-size:
          50px $skeleton-widget-single-wiki-article-height, /* highlight */
          20% $skeleton-widget-single-wiki-article-height,
          75px $skeleton-widget-single-wiki-article-height,
          35% $skeleton-widget-single-wiki-article-height;

  background-position:
          0 0, /* highlight */
          0px 0,
          100% 0,
          0px 30px;

  animation: shine-widget-single-wiki-article 1.25s infinite;
}

@keyframes shine-widget-single-wiki-article {
  to {
    background-position:
            100% 0, /* move highlight to right */
            0px 0,
            100% 0,
            0px 30px;
  }
}


/**
 *  LATEST WIKI ARTICLES WIDGET
 */

$skeleton-widget-latest-wiki-articles-container-height: 330px;
$skeleton-widget-latest-wiki-articles-height: 70px;

.skeleton-container-widget-latest-wiki-articles {
  width: calc(100%);
  height: $skeleton-widget-latest-wiki-articles-container-height;
}

.skeleton-widget-latest-wiki-articles:empty {
  width: 100%;
  height: $skeleton-widget-latest-wiki-articles-container-height;

  background-image:
          linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, rgba(255, 255, 255, 0) 100%),  /* highlight */
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 20px, transparent 0 ),
          linear-gradient( $gray-lighter 15px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
          50px $skeleton-widget-latest-wiki-articles-height, /* highlight */
          20px $skeleton-widget-latest-wiki-articles-height,
          50% $skeleton-widget-latest-wiki-articles-height,
          40% $skeleton-widget-latest-wiki-articles-height;

  background-position:
          0 0, /* highlight */
          0px 0,
          30px 0,
          30px 30px;

  animation: shine-widget-latest-wiki-articles 1.25s infinite;
}

@keyframes shine-widget-latest-wiki-articles {
  to {
    background-position:
            50% 0, /* move highlight to right */
            0px 0,
            30px 0,
            30px 30px;
  }
}
