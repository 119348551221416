@use '@angular/material' as mat;
@import "variables";
@import "mixins";

.shepherd-button {
  background: $coyo-button-primary-background-color;
  border: 0;
  border-radius: $coyo-border-radius-md;
  color: $coyo-button-primary-color;
  margin-right: 8px;
  padding: 0 12px;
  min-width: 80px;
  line-height: 40px;
  transition: box-shadow 0.2s cubic-bezier(0.35, 0, 0.25, 1);

  &:disabled {
    background: $coyo-button-disabled-background-color;
    color: $coyo-button-disabled-color;
  }

  &:not(:disabled):hover {
    background: $coyo-button-primary-hover-background-color;
  }

  &.shepherd-button-secondary {
    background: $coyo-button-poor-background-color;
    color: $coyo-button-poor-color;

    &:disabled {
      color: $coyo-button-disabled-color;
    }

    &:not(:disabled):hover {
      background: $coyo-button-poor-hover-background-color;
    }
  }

  &.shepherd-button-skip {
    margin-right: auto;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $coyo-focus-color;
    outline: 0;
  }
}

.shepherd-footer {
  display: flex;
  margin-bottom: -$coyo-dialog-padding-vertical;
  padding: $coyo-dialog-padding-vertical 0;

  .shepherd-button:last-child { margin-right: 0; }
}

.shepherd-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0
}

.shepherd-header {
  margin-top: -$coyo-dialog-padding-vertical;
  padding: $coyo-dialog-padding-vertical 0 #{$coyo-dialog-padding-vertical * 0.5};
}

.shepherd-text {
  font-size: 14px;
  line-height: 22px;
  color: $coyo-secondary-text-color;

  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
}

.shepherd-content {
  outline: none;
}

.shepherd-element {
  @include mat.elevation(24);
  background: $coyo-primary-background-color;
  border-radius: $coyo-border-radius-lg;
  padding: $coyo-dialog-padding-vertical $coyo-dialog-padding-horizontal;
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s;
  z-index: 9999;

  &.shepherd-enabled {
    opacity: 1;
  }

  &[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
  }
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=40);
  filter: alpha(opacity=40);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997;
  fill: $n600;

  &.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .4;
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s;

    path {
      pointer-events: all;
    }
  }
}

