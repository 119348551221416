.messaging-content {
  > div:focus {
    outline: none;
  }
}

.sidebar.messaging {

  &.compact {
    .messaging-channels {

      // css only IE9, IE10, IE11
      _::selection,
      .messaging-channels-ch {
        @media screen and (min-width: $screen-lg-min) {
          margin-left: auto;
          margin-right: auto;
          padding: 12px 0;
        }
      }

      .messaging-channels-ch {
        @media screen and (min-width: $screen-lg-min) {
          display: flex;
          justify-content: center;
        }

        .messaging-channels-avatar {
          position: relative;
          @media screen and (min-width: $screen-lg-min) {
            margin: 0 6px;
          }
          width: 40px;

          .mat-icon {
            position: absolute;
            bottom: -6px;
            right: -6px;
            color: $color-white;
            background-color: $color-messaging-sidebar-bg;
            border-radius: 50%;
          }

          .unread-count {
            &.compact-view {
              display: none;
              @media screen and (min-width: $screen-lg-min) {
                @include unread-bubble();
                right: -4px;
                top: -4px;
                position: absolute;
                z-index: 100;
              }
            }
          }
        }
      }
    }
  }

  .messaging-channels {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .messaging-header {
      .messaging-header-icon-bar {
        a.presence-status .zmdi {
          @include presence-status-color();
          &:hover {
            opacity: .75;
          }
        }
      }
    }

    .messaging-channels-ch {
      cursor: pointer;
      display: block;
      padding: 12px 18px;
      transition: background-color linear .1s;
      outline: none;

      // Adjust colors on hover
      &:hover {
        background-color: $color-black;

        .messaging-channels-avatar .messaging-channel-avatar {
          .user-avatar .online-status {
            border-color: $color-black;
          }
        }

        .messaging-channels-avatar .mat-icon {
          background-color: $color-black;
        }

        .unread-count {
          border-color: $color-white;
        }
      }

      .messaging-channels-avatar {
        float: left;
        margin-right: 12px;
        min-height: 40px;

        .messaging-channel-avatar {
          .user-avatar {
            .online-status {
              border-color: $color-messaging-sidebar-bg;
              right: -2px;
              top: 2px;
            }
            .user-external {
              border-color: $color-messaging-sidebar-bg;
            }
          }
        }
      }

      .messaging-channels-ch-info {
        color: $color-gray-light;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        min-height: 40px;

        .line {
          align-items: center;
          color: $color-gray-dark;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          line-height: 20px;

          .user {
            overflow:hidden;
            color: $color-white;
            flex-grow: 1;
            display:flex;
            align-items: center;
            margin-right: 6px;

            .mat-icon {
              position: relative;
              left: -2px;
            }

            span {
              @include ellipsis();
              min-width: 0;
            }
          }

          .unread-count {
            align-items: center;
            flex-grow: 0;
            min-width: 18px;
            z-index: 100;
            @include unread-bubble();
          }

          .last-message {
            flex-grow: 1;
            margin-right: 6px;
            @include ellipsis();
          }

          .time {
            flex-grow: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media print, screen and (min-width: $screen-lg-min) {
    &.compact .messaging-channels {
      .messaging-channels-ch {
        .messaging-channel-avatar {
          flex: 1;
          padding: 0;
        }

        .messaging-channels-ch-info {
          display: none;
        }
      }
    }
  }
}
