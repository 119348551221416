/**
 * The links below are useful for individual styling of ngTagsInput.
 *
 * @see http://mbenford.github.io/ngTagsInput/demos
 * @see http://mbenford.github.io/ngTagsInput/documentation/guides/bootstrap
 */
tags-input {
  > *:focus {
    outline: none;
  }

  .tags {
    @extend .form-control;
    height: auto;
    padding: 3px;

    &.focused {
      border-color: $coyo-input-border-focus;
      box-shadow: 0 0 0 1px $coyo-input-border-focus;
    }

    .input {
      margin: 0;
      padding: ($padding-base-vertical - 3px) ($padding-base-horizontal - 3px);
      height: auto;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      line-height: $line-height-base;

      &.invalid-tag {
        color: #b70218;
      }
    }

    .tag-item {
      height: auto;
      margin: 0 3px 0 0;
      padding: 3px 8px;
      background: $color-gray-lighter;
      border-color: $input-border;
      border-radius: $border-radius-small;
      color: $text-color;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      line-height: $line-height-base;

      .remove-button {
        font-weight: normal;
        color: $text-muted;

        &:active,
        &:focus,
        &:hover {
          color: darken($text-muted, 15%);
        }
      }
    }
  }
}
