@import "variables";
@import "mixins";

.dropdown-menu {
  padding: 0;
  @include box-shadow(0, 1px, 1px, 0, rgba(0,0,0,.05));

  > li > button,
  > li > a,
  > li > * > button,
  > li > * > a {
    color: $dropdown-link-color;
    padding: $padding-base-vertical $padding-base-horizontal;

    display: flex;
    align-items: center;
  }

  .divider {
    margin: 0;
  }

  i, mat-icon {
    margin-left: -4px;
    margin-right: 4px;

    &.zmdi {
      padding-bottom: 1px;
    }
  }
}
