@use '@angular/material' as mat;
@import "mixins";
@import "variables";

coyo-emoji-picker {
  display: inline-block;
  line-height: 1;

  .emoji-mart {
    @include no-select();
    display: block;
    border: none;
    border-radius: 0;
    background: transparent;
    font-family: $font-family-base;
    font-size: $font-size-base;

    .emoji-mart-anchors {
      color: $n200;
    }

    .emoji-mart-anchor:hover {
      color: $n300;
    }

    .emoji-mart-anchor-selected {
      color: $b400 !important;

      .emoji-mart-anchor-bar {
        background-color: $b400 !important;
      }
    }

    .emoji-mart-bar {
      border-color: $n50;
    }

    .emoji-mart-anchor,
    .emoji-mart-emoji,
    .emoji-mart-emoji span {
      cursor: pointer;
      outline: none;
    }

    .emoji-mart-category-label span {
      font-weight: bold;
      color: $n300;
    }
  }

  .emoji-picker-overlay {
    z-index: 3;
    background-color: $color-white;
    border-radius: 4px;
    padding: 0;
    overflow: hidden;
    @include mat.elevation(4);
  }
}

coyo-emoji-picker {
  .popover {
    &.top { margin-bottom: 2px !important; }
    &.left { margin-right: 2px !important; }
    &.right { margin-left: 2px !important; }
    &.bottom { margin-top: 2px !important; }
  }

  .popover-arrow {
    display: none !important;
  }
}

popper-content {
  width: 245px;
}
