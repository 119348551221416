@use "sass:math";

h1, .h1 { line-height: floor($font-size-h1 * $headings-line-height); }
h2, .h2 { line-height: floor($font-size-h2 * $headings-line-height); }
h3, .h3 { line-height: floor($font-size-h3 * $headings-line-height); }
h4, .h4 { line-height: floor($font-size-h4 * $headings-line-height); }
h5, .h5 { line-height: floor($font-size-h5 * $headings-line-height); }
h6, .h6 { line-height: floor($font-size-h6 * $headings-line-height); }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: #{math.div(2, 3)}em;
  margin-bottom: #{math.div(1, 3)}em;

  small, .small {
    font-weight: 300;
  }
}

small, .small {
  font-size: math.div(100% * $font-size-small, $font-size-base);
}

//.size-normal, .root-em {
//  font-size: 1rem !important;
//}

.light {
  font-weight: 300 !important;
}
.normal {
  font-weight: 400 !important;
}
.bold {
  font-weight: 700 !important;
}
