.modal-app-settings.ui-select-translation {
  z-index: 1;
  right: 75px !important;

  @media screen and (max-width: 991px) {
     right: 50px !important;
  }

  @media screen and (max-width: 767px) {
    right: 8px !important;
    top: 60px !important;
    position: absolute;
  }
}

@media screen and (max-width: 767px) {
  .modal-app-settings.modal-header {
    background-color: transparent !important;
    background-image: linear-gradient(to bottom, #101d30, #101d30 50%, transparent 50%, transparent 100%);
    background-image: -webkit-linear-gradient(to bottom, #101d30, #101d30 50%, transparent 50%, transparent 100%);
    min-height: 92px !important;
  }
}

.app-settings {
  color: $text-color;
}