.top-left {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  > * {
    text-align: left;
  }
}

.top-center {
  justify-content: center;
  align-items: flex-start;
  text-align: center;

  > * {
    text-align: center;
  }
}

.top-right {
  justify-content: flex-end;
  align-items: flex-start;
  text-align: right;

  > * {
    text-align: right;
  }
}

.left {
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  > * {
    text-align: left;
  }
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;

  > * {
    text-align: center;
  }
}

.right {
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  > * {
    text-align: right;
  }
}

.bottom-left {
  justify-content: flex-start;
  align-items: flex-end;
  text-align: left;

  > * {
    text-align: left;
  }
}

.bottom-center {
  justify-content: center;
  align-items: flex-end;
  text-align: center;

  > * {
    text-align: center;
  }
}

.bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;

  > * {
    text-align: right;
  }
}
