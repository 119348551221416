@import "variables";

coyo-comment p {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

coyo-comment .highlighted {
  p {
    display: initial;
    background: $b75;
    border-radius: .25rem;
  }
}
