.page-entry-count {
  font-size: 13px;

  .entry-count-heading {
    color: $color-gray;
  }

  .entry-count-number {
    font-size: 15px;
  }
}