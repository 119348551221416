.help-dialog {
  table {
    width: 100%;

    th, td {
      padding: 8px;
      border: 1px solid $coyo-border-color;
    }
  }
}
