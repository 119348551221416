coyo-image-widget-settings {
  .image-preview {
    img {
      -o-object-fit: contain;
      border-radius: 3px;
      height: auto;
      max-height: 300px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
