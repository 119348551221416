.members-list-options {
  position: absolute;
  top: 0;
  right: 0;

  > a {
    color: $color-gray;
  }
}

.members-tabs-wrapper {
  display: flex;

  .members-tabs {
    flex: 1;
  }

  .user-invite-action {
    flex: 0 1 auto;
    border-left: 2px solid $color-gray-lighter;
    border-bottom: 3px solid $color-gray-light;

    .btn {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &:hover {
      border-bottom: 3px solid $color-gray;
    }
  }
}

.ws-card-actions {
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid $color-gray-light;
  background-color: $color-white;
  border-radius: 0 5px 5px 0;
  box-shadow: -1px 0px 0px 0px $color-white;

  .btn.btn-inverse {
    align-items: center;
    border: 0;
    display: flex;
    flex: 1;
    padding: 0 8px;

    @media print, screen and (min-width: $screen-md-min) {
      border-radius: 0 5px 5px 0;
    }
  }
}

.ws-card-actions-multiple .btn {
  &:last-child {
    border-top: 1px solid $color-gray-light;
  }

  @media print, screen and (min-width: $screen-md-min) {
    &:first-child {
      border-radius: 0 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px;
      border-top: 1px solid $color-gray-light;
    }
  }
}
