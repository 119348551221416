@import "variables";
@import "mixins";

.widget-title {
  color: $n500;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: .4px;
  margin: 0 0 8px;
  @include ellipsis();
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      .highlighted {
        background-color: yellow;
        font-weight: bold;
        text-decoration: none !important;
      }
    }
  }
}
