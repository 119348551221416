// Mixins - Set the placeholder color
// --------------------------------------------------

// example usage:
// @include placeholder-color(#ccc)
@mixin placeholder-color($color) {
  // scss-lint:disable VendorPrefix
  // WebKit, Blink, Edge
  &::-webkit-input-placeholder {
    color: $color;
  }

  // Mozilla Firefox 4-18
  &:-moz-placeholder {
    color: $color;
  }

  // Mozilla Firefox 19+
  &::-moz-placeholder {
    color: $color;
  }

  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $color;
  }
  // scss-lint:enable VendorPrefix
}
