@media print {

  @page {
    size: A4;
  }

  body, .container-main {
    background-color: transparent !important;
    color: black;
    width: $print-body-width !important;
    color-adjust: exact;
    printer-colors: exact;
    -webkit-print-color-adjust: exact;
  }

  .container-wrapper {
    display: block;

    .container-main .content {
      margin: 0;
      padding: 0;
      width: 100%;

      .content-with-sidebar {
        display: block; // required for firefox to print more than one page
      }
    }
  }

  .print-preview {
    page-break-inside: auto;
    break-inside: auto;
  }

  .panel-body + .panel-heading.article-header {
    page-break-before: always;
    break-before: page;
  }

  h1, h2, h3, h4, h5 {
    page-break-before: auto;
    break-before: auto;
    page-break-after: avoid;
    break-after: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    & + p, & + div {
      page-break-before: avoid;
      break-before: avoid;
    }
  }

  .widget-layout-row {
    page-break-after: auto;
    break-after: auto;
  }

  .widget-inner {

    .rss-widget .rss-entry {
      page-break-after: auto;
      break-after: auto;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    coyo-single-file-widget, coyo-blog-article-widget, coyo-wiki-article-widget, coyo-downloads-widget {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }

  coyo-user-profile-widget, coyo-file-image-preview, coyo-image-widget, coyo-user-avatar {
    page-break-inside: avoid;
  }

  .rte-widget .fr-view hr {
    page-break-after: auto;
    break-after: auto;
  }

  p {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .messaging-sidebar-wrapper, .navigation, .main-navigation, coyo-landing-page-navigation, .cui-spinner,
  oyoc-view-edit-options-bar, oyoc-moderator-bar, .coyo-ng-select-container {
    display: none !important;
  }

  .article-status-options {
    display: none !important;
  }

  .panel .panel-title {
    overflow: visible !important;
  }

  // if there is a modal, do not print body content
  .modal ~ .body, .mat-dialog-open .body {
    display: none;
  }

  // specific print-preview style
  .print-preview {
    width: $print-body-width;
    transform: none !important;
    height: auto !important;
  }

  // bootstrap modal
  .modal-dialog {
    display: block !important;
    margin: 0 !important;
  }

  .modal {
    position: static !important; // position fixed causes chrome to stop after first page

    .modal-content-wrapper {
      display: block;
    }
  }

  .modal-header, .modal-footer, .modal-backdrop {
    display: none !important;
  }

  // angular material modal
  .cdk-global-scrollblock, .cdk-overlay-container, .cdk-global-overlay-wrapper {
    position: static !important;
    overflow-y: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-width: 100% !important;
  }

  .cdk-overlay-backdrop, .mat-dialog-title, .mat-dialog-actions, .mat-dialog-close {
    display: none !important;
  }

  .mat-dialog-container, .mat-dialog-content, .mat-dialog-content > .panel {
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    max-height: initial !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  /* these styles are required so that all browsers render page breaks correctly */
  .cdk-overlay-container, .cdk-global-overlay-wrapper, .cdk-overlay-pane, .mat-dialog-container {
    position: static !important;
    display: block !important;
    overflow: visible !important;
    height: auto !important;
  }

  // individual styling for layouts
  .page, .workspace, .events-list {
    .content-sidebar {
      display: none !important;
    }

    & > .panel {
      .sender-actions, coyo-sender-cover, .page-avatar-container, .workspace-avatar-container {
        display: none;
      }
    }
  }

  .state-main-page-show-apps-wiki-list-view,
  .state-main-workspace-show-apps-wiki-list-view,
  .state-main-page-show-apps-blog-list-view,
  .state-main-workspace-show-apps-blog-list-view {
    .page, .workspace, .events-list {
      & > .panel {
        display: none !important;
      }

      .content-main > .widget-slot-content {
        display: none !important;
      }
    }
  }

  @include print-display-widget-layout();

  .article-view {
    @include print-exclude-widgets();

    coyo-comment-list, .panel-footer, .wiki-subarticles, .panel-nav, cui-card-footer, cui-card-menu {
      display: none !important;
    }
  }
}

.print-preview {
  @include print-display-widget-layout();
  @include print-exclude-widgets();
}
