@import "variables";

.mention-wrapper {
  z-index: 1000;
}

.mention-selection {
  max-width: initial;
  padding: 0;
  border: 0;
  box-shadow: none;
  background: none;

  .dropdown-menu {
    display: block;
    position: static;
    float: none;
    margin: 0;
  }

  li {
    width: 280px;
    border-bottom: 1px solid $color-gray-light;

    &:last-child {
      border-bottom: none
    }

    > a {
      padding: 2px 8px;

      &.hover {
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
      }
    }
  }
}
