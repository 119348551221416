.message-form {
  .coyo-btn-link:not([disabled]) {
    color: $n100;

    &:hover {
      color: $n50;
      background-color: transparent;
    }
  }

  .coyo-btn-link[disabled] {
    color: $n200;
  }
}

// this is a hotfix to not display the icons overlay on the message-form
// this should be re-checked when the message-form gets migrated to angular material
.message-form-actions {
  coyo-emoji-picker {
    button {
      color: $n100;
    }

    .mat-button-focus-overlay {
      display: none;
    }
  }

  .attachment-button-icon {
    color: $n100;
  }

  .cui-button {
    &:hover {
      background: transparent;
    }

  }

  .cui-button[data-cui-mode=ghost]:not([disabled]):hover {
    background-color: transparent;
    color: $n100;
  }
}
