.user-chooser {
  > .modal-close {
    position: absolute;
    top: 16px;
    right: 30px;
    cursor: pointer;
    font-size: 24px;
    color: $color-gray;

    &:hover {
      color: $color-gray-darker;
    }
  }

  &.modal-body {
    height: 500px;
    overflow: auto;

    @media screen and (min-height: 1000px) {
      height: 600px;
    }
    @media screen and (min-height: 1200px) {
      height: 700px;
    }

    @media screen and (max-width: $screen-xs-max) {
      padding: 0;

      .nav-tabs > .uib-tab > a {
        padding: 12px 16px;
      }
    }
  }

  .user-chooser-list-wrapper {
    padding: 4px 8px;

    @media print, screen and (min-width: $screen-sm-min) {
      padding: 12px 16px;
    }
  }

  .user-chooser-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .btn-more {
    margin: 24px auto;
    display: block;
  }

  .filterbox {
    > .panel.panel-default.panel-filterbox {

      @media print, screen and (min-width: $screen-sm-min) {
        border: 1px solid $color-gray-light;
        margin: 16px 0 0;
      }
    }

    @media print, screen and (min-width: $screen-sm-min) {
      .fb-filter {
        margin-top: 2px;
      }
    }
  }
}

.user-chooser-count {
  margin: 8px 0;
  padding: 0;
  clear: both;

  @media print, screen and (min-width: $screen-md-min) {
    margin-left: 8px;
  }
}

