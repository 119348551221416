@import "variables";
@import "mixins";

.lightbox {
  .mat-dialog-container {
    background-color: black;
  }

  .mat-icon-button:not([disabled]) .mat-button-focus-overlay {
    // TODO should be set to #D400 when it's available in pattern library
    background-color: #1B1B1B;
  }

  .mat-icon-button {
    color: $n0;
  }

  .mat-dialog-close {
    position: relative;
    top: unset;
    right: unset;
    float: right;
  }

  coyo-file-image-preview .pi-preview-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include sm-and-down() {
  .lightbox {
    max-width: 100% !important;
    height: 100% !important;

    .mat-dialog-container {
      border-radius: 0px;
    }

    .prev-media {
      position: absolute;
    }

    coyo-file-image-preview .pi-preview-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .next-media {
      position: absolute;
      right: 0px;
    }
  }
}

