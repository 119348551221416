/**
 * Overwrites google file picker css to enforce that the picker is in front of the timeline item post and
 * messaging-sidebar.
 */
.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
