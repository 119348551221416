$message-border-radius-max: 14px;
$message-border-radius-min: 3px;
$message-margin-top: 4px;
.sidebar.messaging {
  overflow: visible;

  .messaging-channel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .messaging-channel-overlay:before {
      content: '\f22a';
    }

    .messaging-channel-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      border: 2px dashed $color-white;
      z-index: 2;
      font-size: 5em;
      font-family: 'Material-Design-Iconic-Font';
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      color: $color-blue;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message-day-slot {
      overflow-x: hidden;
    }

    .message-form-row {
      background-color: $color-black;
      display: flex;

      textarea.form-control {
        background: transparent;
        border: 0;
        border-radius: 0;
        color: $color-white;
        flex: 1;
        padding: 8px 12px;
        resize: none;

        &:focus {
          box-shadow: none !important;
        }

        &[disabled] {
          color: $color-gray;
        }
      }

      > a {
        align-self: center;
        color: $color-gray;
        display: inline-block;
        flex: none;
        font-size: 20px;
        padding: 0 8px;

        &:hover {
          color: $color-gray-dark;
        }
      }
    }

    .message-form-controls {
      position: absolute;
      width: 100%;

      button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .message-form-controls,
    .message-form-attachment-context-menu {
      ul {
        @media screen and (min-width: $screen-md-min) {
          margin-bottom: 35px;
          margin-left: 50px;
        }

        a {
          @media screen and (min-width: $screen-md-min) {
            color: $color-gray-darker !important;
            padding: 8px 16px;
          }
          display: block;
          font-size: 1rem;
          padding: 10px 16px;
          color: $color-white;
        }
      }

      .context-menu-title {
        color: $color-gray-darker;
      }
    }

    .message-form-attachments {
      margin-top: 34px;
    }

    .message-form-attachment,
    .message-form-controls {
      background-color: $color-black;
      color: $color-gray;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 1px;

      .zmdi {
        &.item-active {
          color: $color-blue;
        }
      }
    }

    .message-form-row,
    .message-form-attachment,
    .message-form-controls {
      a, .btn, .progress-info {
        border: 0;
        color: $color-gray;
        display: inline-block;
        font-size: 14px;
        line-height: 21px;
        outline: 0;
        padding: 6px 12px;
        vertical-align: initial;

        &:hover:not([disabled]) {
          color: $color-gray-lighter;
        }
      }

      .zmdi {
        font-size: 15px;
        line-height: 21px;
      }
    }

    .message-form-attachment {
      display: flex;

      &.uploading {
        color: $color-gray-dark;
      }

      &.has-upload-error {
        transition: background .2s ease-in-out;

        &:hover {
          background-color: $color-messaging-sidebar-bg;
          cursor: pointer;
        }
      }

      .message-form-attachment-name {
        flex: 1;
        padding: 6px 12px;
        @include ellipsis();
      }

      .message-form-attachment-opt {
        flex: none;

        .zmdi {
          color: $color-red;
        }
      }

      .file-icon {
        color: $color-gray-dark;
      }
    }

    coyo-divider {
      color: $color-gray-dark;
      font-size: 13px;
      text-transform: capitalize;

      a { color: $color-gray-light; }
      a:hover { color: $color-gray; }
    }

    .message-wrapper {
      margin-left: 14px;
      margin-right: 14px;

      .message-notification,
      .message-author {
        @include break-word();
      }

      .message-text {
        @include break-word();

        a {
          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
        }
      }

      .message {
        background-color: $color-black;
        color: $color-white;
        display: block;
        font-size: 14px;
        margin: 0 12% 0 0;
        padding: 12px;

        &:focus {
          outline: none;
        }

        .file-icon {
          color: $color-white;
        }

        &.animate {
          @include animate(fadein .15s);
        }

        & > div:not(:last-child).message-body {
          &.with-message,
          &.with-author {
            padding-bottom: 12px;
          }
        }

        &.hide-text.hide-author {
          &.has-attachments {
            padding: 0;
            .attachment-list {
              padding: 12px;
            }
          }

          .message-body {
            .message-author,
            .message-text {
              display: none;
            }
          }
        }

        .message-text {
          > *:first-child {
            margin-top: 0;
          }

          > *:last-child {
            margin-bottom: 0;
          }

          a {
            color: $color-gray-light;

            &:hover {
              color: $color-gray-lighter;
            }
          }
        }

        &.hide-text .message-text {
          display: none;
        }

        .message-author {
          font-weight: bold;
        }

        &.hide-author .message-author {
          display: none;
        }

        .message-created {
          color: $color-gray;
          font-size: 12px;
        }

        .message-channel-attachments-uploading {
          & > div {
            padding: 8px 12px;
          }
        }

        // attachments
        .attachment-previews {
          margin: 0;

          a {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
          }

          .attachment-previews-title {
            color: $color-gray-dark;
            padding: 4px 12px;
          }

          .item-attachment {
            max-width: 33.33%;
            width: 33.33%;

            coyo-file-preview {
              padding: 0;

              .pi-container {
                border: 0 none;
                width: 100%;

                .pi-file-icon {
                  align-items: center;
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: center;
                  margin-top: 0;

                  & > .zmdi-hc-5x {
                    font-size: 48px;
                  }
                }
              }
            }

            &:first-of-type {
              max-width: 100%;
              width: 100%;
            }
          }
        }

        .attachment-list {
          display: flex;
          flex-direction: column;
          padding: 0;

          .attachment-list-title {
            color: $color-gray-dark;
            padding: 4px 12px;
          }
        }


        .no-preview-available-text {
          color: $color-white;
        }

        .message-channel-uploading-attachments {
          .message-attachment {
            border-top: 1px solid $color-messaging-sidebar-bg;
            color: $color-gray-lighter;
            display: flex;
            opacity: .85;
            padding: 8px 12px;

            &:hover {
              opacity: 1;
            }

            .message-form-attachment-name {
              @include ellipsis();
            }

            .message-form-attachment-opt {
              flex: none;
            }
          }
        }
      }

      &:not(.own) > .message {
        &.first {
          border-radius: $message-border-radius-max $message-border-radius-max $message-border-radius-max $message-border-radius-min;

          &.hide-text.hide-author {
            .attachment-previews {
              border-radius: $message-border-radius-max $message-border-radius-max 0 0;

              &:only-child {
                border-radius: $message-border-radius-max $message-border-radius-max $message-border-radius-max $message-border-radius-min;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-min;
            border-bottom-right-radius: $message-border-radius-max;
          }
        }

        &.middle {
          border-radius: $message-border-radius-min $message-border-radius-max $message-border-radius-max $message-border-radius-min;
          margin-top: $message-margin-top;

          &.hide-text {
            .attachment-previews {
              border-radius: $message-border-radius-min $message-border-radius-max 0 0;

              &:only-child {
                border-radius: $message-border-radius-min $message-border-radius-max $message-border-radius-max $message-border-radius-min;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-min;
            border-bottom-right-radius: $message-border-radius-max;
          }
        }

        &:not(.first).last {
          border-radius: $message-border-radius-min $message-border-radius-max $message-border-radius-max $message-border-radius-min;
          margin-top: $message-margin-top;

          &.hide-text {
            .attachment-previews {
              border-radius: $message-border-radius-min $message-border-radius-max 0 0;

              &:only-child {
                border-radius: $message-border-radius-min $message-border-radius-max $message-border-radius-max $message-border-radius-min;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-min;
            border-bottom-right-radius: $message-border-radius-max;
            border-top-right-radius: 0;
          }
        }
      }

      // Author == Current User
      &.own > .message {
        background-color: lighten($color-black, 15%);
        margin: 0 0 0 12%;

        &.first {
          border-radius: $message-border-radius-max $message-border-radius-max $message-border-radius-min;

          &.hide-text {
            .attachment-previews {
              border-radius: $message-border-radius-max $message-border-radius-max 0 0;

              &:only-child {
                border-radius: $message-border-radius-max $message-border-radius-max $message-border-radius-min;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-max;
            border-bottom-right-radius: $message-border-radius-min;

          }
        }

        &.middle {
          border-radius: $message-border-radius-max $message-border-radius-min $message-border-radius-min $message-border-radius-max;
          margin-top: $message-margin-top;

          &.hide-text {
            .attachment-previews {
              border-radius: $message-border-radius-max $message-border-radius-min 0 0;

              &:only-child {
                border-radius: $message-border-radius-max $message-border-radius-min $message-border-radius-min $message-border-radius-max;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-max;
            border-bottom-right-radius: $message-border-radius-min;
          }
        }

        &:not(.first).last {
          border-radius: $message-border-radius-max $message-border-radius-min $message-border-radius-min $message-border-radius-max;
          margin-top: $message-margin-top;

          &.hide-text {
            .attachment-previews {
              border-radius: $message-border-radius-max $message-border-radius-min 0 0;

              &:only-child {
                border-radius: $message-border-radius-max $message-border-radius-min $message-border-radius-min $message-border-radius-max;
              }
            }
          }

          .attachment-previews:only-child {
            border-bottom-left-radius: $message-border-radius-max;
            border-bottom-right-radius: $message-border-radius-min;
          }
        }
      }

      .message-meta-info {
        clear: both;
        height: 22px;
        margin-bottom: 4px;
        max-height: 22px;
      }

      &.optimistic-pending,
      &.has-error {
        .message-status {
          clear: both;
          line-height: 22px;
          opacity: 1;

          .message-error {

            .message-actions {
              margin: 0 0 0 12%;
              padding: 1px;

              .message-replay,
              .message-delete {
                color: $color-gray-dark;
                padding: 0;

                &:hover {
                  color: $color-white;
                }

                i {
                  position: relative;
                  top: -2px; // fix optical alignment
                  width: 15px;
                  margin: 0;
                }
              }
            }

            .error-message {
              color: $color-orange;
              font-size: small;
            }
          }
        }
      }

      .no-author.message-time {
        text-align: center;
      }

      &.own {
        .message-meta-info {
          > .message-status,
          > .message-time {
            text-align: right;
          }
        }
      }
    }

    .message-time {
      color: $color-gray-dark;
      font-size: small;
    }

    .message-status {
      color: $color-gray-dark;
      display: block;
      font-size: 20px;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    .message-notification {
      color: $color-gray;
      font-size: 13px;
      padding: 4px 12px;
      text-align: center;
    }
  }

  .message-form-row {
    max-height: 35%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
  }
}
