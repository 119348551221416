/**
 * Apply this mixin on the direct child elements of a panel-body if these elements display widget buttons.
 */
@mixin widget-btn-list-item() {
  &:last-child {
    margin-bottom: -$padding-base-horizontal;
  }
}

/**
 * Style a widget button
 */
@mixin widget-btn() {
  @include ellipsis();
  text-align: left;
  margin-left: -#{nth($panel-body-padding, 2) - 12px};
  margin-right: -#{nth($panel-body-padding, 2) - 12px};
  width: calc(100% + #{2*(nth($panel-body-padding, 2) - 12px)});

  @include xs {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -#{nth($panel-xs-body-padding, 2) - 6px};
    margin-right: -#{nth($panel-xs-body-padding, 2) - 6px};
    width: calc(100% + #{2*(nth($panel-xs-body-padding, 2) - 6px)});
  }
}

/**
 * Style a single file widget
 */
@mixin split-view-single-file($max-height) {
  display: flex;
  flex-direction: row;

  .single-file-widget-file-image {
    width: 33%;
    border-bottom: 0 none;
    max-height: $max-height;

    .zmdi-hc-5x {
      margin: 15px 0;
    }

    .img-file-preview {
      .pi-container {
        height: 100%;
      }
    }
  }

  .single-file-widget-details-text {
    width: 67%;
    padding: 24px 30px 24px 30px;

    h3 {
      text-align: left;

      input {
        text-align: left;
      }
    }

    .single-file-widget-details-infos {
      text-align: left;
    }
  }
}
