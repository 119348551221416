.sidebar.messaging {
  .messaging-channel-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .zmdi-plus-circle-o {
      color: $color-green !important;
    }
    .zmdi-accounts {
      color: $color-white !important;
    }

    .form-group {
      background-color: $color-black;
      display: flex;
      margin: 12px 0;
      padding: 10px 12px 10px 18px;

      .icon {
        width: 24px;

        .zmdi {
          color: $color-gray;
          flex: none;
          font-size: 18px;
          margin-top: 4px;
        }
      }

      input {
        flex: 1;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        color: $color-gray-light;
        outline: 0;
      }
    }

    .form-group-users {
      margin-bottom: 1px;
    }

    .messaging-channel-form-user-list {
      .messaging-channel-form-user-item {
        align-items: center;
        background-color: $color-black;
        color: $color-gray;
        display: flex;
        margin-bottom: 1px;
        padding: 8px 12px;

        coyo-user-avatar {
          flex: none;

          .online-status {
            border-color: $color-black;
          }

          .user-external {
            border-color: $color-black;
          }
        }

        .user-name {
          flex: 1;

          @include ellipsis();
        }

        &> .zmdi {
          flex: none;
          margin-left: 4px;
          text-align: right;
        }

        &:hover {
          background-color: lighten($color-black, 3%);
          color: $color-gray-light;

          .user-avatar .online-status {
            border-color: lighten($color-black, 3%);
          }
        }
      }
    }
  }
}
