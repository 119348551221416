.browser-no-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999999;
}

.browser-no-js,
.browser-error-page {
  background-color: $color-primary;
  color: $color-white;
  font-size: 150%;
  font-weight: 100;
  line-height: 1.5em;

  h1, p {
    margin: 0 auto;
    max-width: 80%;
  }

  h1 {
    font-size: 500%;
    font-weight: 300;
    line-height: 1em;
    padding: .5em 0 .25em;
  }

  p {
    padding: .5em 0 .25em;
  }

  a {
    color: $color-white;
    font-weight: 400;
  }

  small {
    color: $color-gray-light;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 14px;
    line-height: 1em;
  }

  @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    font-size: 200%;
  }

  @media print, screen and (min-width: $screen-lg-min) {
    font-size: 250%;
  }
}

.browser-error-bar {
  .browser-warning {
    background: $color-red;
    color: $color-white;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: .9;
    z-index: 9999999;

    span {
      display: block;
      margin: 0 auto;
      padding: 13px 0;
      text-align: center;
      width: 95%;
    }

    a {
      color: $color-white;
      font-weight: 600;
    }
  }
}
