.context-menu {

  &.open > .dropdown-menu {
    overflow-y: auto;

    @media screen and (min-width: $screen-sm-max) {
      max-height: 325px;
    }

    @media screen and (max-width: $screen-sm-max) {
      display: block;
      top: auto;
    }
  }

  .dropdown-menu {
    z-index: 1050;

    .btn {
      text-align: left;
      border: 0;
      border-radius: 0;
      margin: 0;
    }

    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: 0;
      padding: 16px;
      flex-direction: column;
      justify-content: flex-end;

      transition: all .3s;

      &:focus {
        outline: none;
      }

      li {
        background-color: transparent;
        border: 0;

        &.divider {
          background-color: #ebecf0;
        }
      }

      li.context-menu-close {
        &> .btn,
        &:last-child > .btn {
          margin-top: 16px;
          border-radius: 8px;
          text-align: center;
          color: #697687;
          font-weight: 600;
          justify-content: center;
        }
      }

      li.context-menu-title {
        color: $color-white;
        text-align: center;
        margin-bottom: 16px;
      }

      li.context-menu-danger > .btn {
        color: $color-red;
      }

      li:first-child > .btn {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      li:nth-last-child(1) > .btn {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .btn {
        padding: 12px 24px;
        color: #000;
        background-color: #fff;
        border-radius: 0;
        border-bottom: 1px solid #ebecf0;
        line-height: 24px;

        &:hover,
        &:focus,
        &:active {
          background-color: #f3f4f5;
        }

        span {
          vertical-align: middle;
        }

        .zmdi {
          width: 20px;
          font-size: 20px;
          margin-right: 12px;
          color: #697687;
          vertical-align: middle;
        }
      }
    }

    .context-menu-title {
      @media print, screen and (min-width: $screen-md-min) {
        padding: 8px 16px;
      }
    }

    // copy of the required default bootstrap styles, but allow for an extra element between ul and li needed to configure the options dynamically
    * > li {
      @media print, screen and (min-width: $screen-md-min) {

        &.active {
          background-color: $dropdown-link-hover-bg;
        }

        > .btn {
          &:hover,
          &:focus {
            background-color: $dropdown-link-hover-bg;
          }

          display: block;
          padding: 8px 16px;
          color: $dropdown-link-color;
          white-space: nowrap;
        }
      }
    }
  }

  &.dropdown {
    @media screen and (max-width: $screen-sm-max) {
      &:before {
        content: '';
        left: 0;
        right: 0;
        background-color: $color-black;
        z-index: 1050;
        opacity: 0;
        pointer-events: none;
        margin-left: -1px;
        margin-right: -1px;
        position: fixed;
        top: 0;
        bottom: 0;
      }

      &.open {
        &:before {
          opacity: 0.75;
          display: block;
          pointer-events: auto;
        }

        .dropdown-menu {
          z-index: 1051;
          max-height: 100%;
        }
      }
    }

    .context-menu-toggle {
      color: $color-gray;

      &:hover {
        color: $color-gray-darker;
      }
    }
  }
}
