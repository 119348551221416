@import 'variables';

coyo-functional-user-chooser .ng-select {
  border: 2px solid transparent;
  border-radius: 4px;

  &:hover {
    color: $n200;
    background-color: $n50;
    border-color: $n50;
  }

  &.ng-select-focused {
    border: 2px solid $coyo-focus-color;
  }

  .ng-select-container {
    cursor: pointer;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    min-height: 36px;
    padding: 2px;

    .ng-value-container {
      min-width: 36px;
      padding-left: 4px;
    }
  }

  .ng-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ng-arrow-wrapper {
    width: 22px;
    padding-right: 0;
    padding-left: 2px;
    &:hover {
      .ng-arrow {
        border-top-color: $text-arrow;
      }
    }
  }

  .ng-dropdown-panel {
    width: 320px;
    left: 0; // ngSelect does not support auto placement :-(
    margin-top: 2px;
    border-radius: $border-radius;
  }
}


// For now, we are unable to reproduce the same result using view encapsulation
@mixin coyo-functional-user-small() {
  .ng-select.ng-select-single .ng-select-container {
    height: 28px;
    min-height: 28px;

    .ng-value-container {
      min-width: 24px;
    }
  }
  .ng-value .sender-avatar {
    height: 20px !important;
    width: 20px !important;
    font-size: 8px !important;
  }
}

// FIXME: remove .form-actions from selector after migration
coyo-timeline-form .form-actions coyo-functional-user-chooser {
  @include sm-and-up() {
    @include coyo-functional-user-small();

    .ng-select .ng-dropdown-panel {
      left: -164px; // ngSelect does not support auto placement :-(
    }
  }
}

// FIXME: remove .social-actions from selector after migration
coyo-timeline-item-footer .social-actions coyo-functional-user-chooser {
  @include coyo-functional-user-small();
  margin-right: -2px;

  .ng-select .ng-dropdown-panel {
    left: -250px; // ngSelect does not support auto placement :-(
  }
}
