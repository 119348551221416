@import "variables";

.widget-teaser {
  .panel {
    overflow: hidden;
  }
}

.teaser-widget {

  .swiper-container {
    border: 1px solid $n50;

    @include md-and-up() {
      border-radius: $border-radius-large;
    }
  }

  .swiper-slide {
    background: #fff;
    cursor: unset;
    display: flex;
    flex-direction: column;
  }

  .swiper-slide a {
    cursor: unset;
  }
}
