.user-chooser-external {
  > .modal-close {
    position: absolute;
    top: 16px;
    right: 30px;
    cursor: pointer;
    font-size: 24px;
    color: $color-gray;

    &:hover {
      color: $color-gray-darker;
    }
  }

  &.modal-body {

    @media screen and (max-width: $screen-xs-max) {
      .nav-tabs > .uib-tab > a {
        padding: 12px 16px;
      }
    }

    div.description {
      margin-top: 10px;
      margin-bottom: 30px;

      @media screen and (max-width: $screen-xs-max) {
        margin-top: 0;
      }
    }

    span.description,
    span.disclaimer {
      display: block;
      margin-bottom: 20px;
    }

    div.email-addresses {
      line-height: 2.5;

      .control-label {
        padding-left: 0px;
      }

      .help-block {
        line-height: 1.5;
        p {
          margin: 0px;
        }
      }

      // deactivate rule from tags-input directive, except when 'has-error' class was set on form
      .form-group:not(.has-error) tags-input.ng-invalid .tags {
        border-color: inherit;
        -webkit-box-shadow: inherit;
        -moz-box-shadow: inherit;
        box-shadow: inherit;
      }
    }
  }

  .help-block .neutral {
    color: $color-gray-darker;
  }
}

.user-card-external {

  .user-card-heading {
    padding: 22px 16px;
  }
}

