.panel.panel-form-app {
  .panel-heading {
    margin: 0;
    padding: 0;
    border: 0;

    .navigation {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: flex-end;

      .panel-heading-item,
      .context-menu-toggle {
        padding: 12px;
        border-left: 1px solid $color-gray-light;
      }
      .context-menu-toggle {
        display: inline-block;
      }
      .search-wrapper.panel-heading-item,
      coyo-page-entry-count.panel-heading-item {
        border: none;
      }
    }

    h3 {
      margin: 16px 30px 0 30px;
      @include xs() {
        margin: 10px 15px 0 15px;
      }
    }

    .description {
      padding: 10px 30px 0 30px;
      display: block;
      @include break-word();
      @include xs() {
        padding: 10px 15px 0 15px;
      }
    }

    hr {
      margin: 15px 30px 15px 30px;
      @include xs() {
        margin: 15px 15px 0 15px;
      }
    }
  }

  .panel-body:not(.panel-body-success) {
    padding-top: 0;
  }

  .dynamic-app-table-header {
    padding-top: 0;
  }

  .dynamic-app-table-row-item {
    @include sm-and-up() {
      .author-container {
        margin-top: -2px;
        margin-bottom: -2px;
      }
    }
  }

  .app-no-fields {
    padding: 0 30px 24px 30px;
  }

  &.panel-form-app-list {
    @include xs() {
      .panel-body {
        padding: 0;
      }
      .dynamic-app-table {
        &-row {
          position: relative;
        }

        .btn-container {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }

  .title-success {
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    color: $color-green;
  }

  .app-icon-success {
    background-color: $color-white;
    margin: 25px;
    border: 3px solid $color-green;

    i {
      color: $color-green;
    }
  }
}

.app-entry-form .form-group:not(:last-child) {
  margin-bottom: 12px;
}

.form-app-tab-bar {
  margin-bottom: 12px;
}
