.label {
  display: inline-block;
  line-height: $line-height-base;
  padding: $padding-xs-vertical + 1 $padding-xs-horizontal;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 80%;
}

.label.label-muted,
.label.label-muted:hover {
  background-color: $color-gray-lighter;
  color: $color-gray;
}
