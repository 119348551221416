@import "variables";
@import "mixins";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";

$rte-text-color: $color-black;
$rte-text-color-inactive: $color-gray;
$rte-border-color: $color-gray-light;
$rte-background-color: $color-white;

@mixin reset-rendering() {
  text-rendering: initial;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

/* Fix for showing the file library (and other modals) in front of the full screen froala. The default value is set
 to max int + !important by froala 🤮 */
.coyo-rte.fr-box.fr-fullscreen, .fr-fullscreen-wrapper {
  z-index: 1010 !important;
}

/* Fix for hiding a line at the bottom of the RTE that is rended after updating to v3.2.6 */
.fr-second-toolbar {
  border: 0;
}

/* Forms */

.form-group {
  .coyo-rte {
    border-radius: 5px;
    border: 1px solid $rte-border-color;
    padding: 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &.focus {
      border-color: $color-black;
      box-shadow: 0 0 0 1px $color-black;
    }
  }
  &.has-error .coyo-rte {
    border-color: $color-red;
    &.focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($color-red, 0.2);
    }
  }
  &.has-success .coyo-rte {
    border-color: $color-green;
    &.focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($color-green, 0.2);
    }
  }
  &.has-warning .coyo-rte {
    border-color: $color-yellow;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($color-yellow, 0.2);
  }
}

/* Toolbar */

.coyo-rte {
  .fr-toolbar {
    border: 0;
    border-radius: 0;
    background: $rte-background-color;
  }

  .second-toolbar {
    width: 0;
    height: 0;
    border: 0;
  }
}
.widget-slot-plain {
  .fr-box.fr-basic {
    padding-top: 2px;
  }
}

.fr-popup,
.fr-toolbar {
  font-family: $font-family-base;
}

ul.fr-dropdown-list li pre {
  border: none;
  border-radius: 0;
}

/* Popups */

.fr-popup {
  .fr-input-line {
    display: flex !important;
    flex-direction: column-reverse;
    margin-bottom: 16px;
    padding: 0;

    input + label,
    select + label,
    textarea + label,
    input.fr-not-empty + label,
    textarea.fr-not-empty + label {
      position: initial;
      padding: 0;
      font-size: $font-size-small;
      line-height: $line-height-small;
      color: $text-color;
    }

    input[type="text"],
    textarea {
      padding: 7px 14px;
      line-height: 1.6;
      border: 1px solid $color-gray-light;
      border-radius: 5px;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

      &:hover {
        border-color: $color-gray-light;
      }

      &:focus {
        padding: 7px 14px;
        border: 1px solid $color-primary;
        box-shadow: 0 0 0 1px $color-primary;
      }
    }

    input[type="text"] {
      height: 38px;
    }
  }

  .fr-checkbox-line label {
    color: $text-color;
    font-weight: normal;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  .fr-checkbox {
    input:focus + span,
    input:checked + span {
      border-color: $color-primary;
    }
    input:not(:checked) + span,
    input:not(:checked):hover + span {
      border: solid 1px $color-gray-light;
    }
    input:not(:checked):focus + span {
      border: solid 2px $color-primary;
    }
    input:checked + span {
      background: $color-primary;
    }
  }

  .fr-popup .fr-layer {
    margin: 16px;
  }
  .fr-color-set.fr-selected-set {
    padding: 16px;
  }

  .fr-action-buttons {
    button.fr-command {
      &, &:hover, &:focus {
        color: $link-color;
      }
    }
  }

  .fr-video-size-layer .fr-video-group .fr-input-line,
  .fr-video-size-layer .fr-video-group .fr-input-line + .fr-input-line {
    width: auto;
    margin-left: 0;
  }
}

/* Modals */

.fr-overlay {
  background: rgba(0,0,0,.7);
  opacity: initial;
}

.fr-modal-wrapper {
  max-width: 600px;
}

.fr-modal {
  @include reset-rendering();
  font-family: $font-family-base;
  line-height: $line-height-base;
  color: $rte-text-color;

  .fr-modal-wrapper div.fr-modal-body {
    padding-bottom: 16px;
  }
}

.fr-word-paste-modal {
  padding: 16px 24px 0 !important;

  div {
    margin-top: 24px !important;
  }
}

/* View box */

.fr-box.fr-basic .fr-element {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

.fr-box.fr-basic .fr-wrapper {
  border: 0;
}

textarea.fr-code {
  font-family: $font-family-monospace;
}

.fr-view {
  overflow: auto;
  @include break-word();

  // links should keep their color at all times (even when coloring text)
  a[href] {
    color: $link-color !important;

    &:hover,
    &:focus {
      color: $link-hover-color !important;
    }
  }

  blockquote {
    color: $gray-darker;
    border-left: 2px solid $gray-darker;
    padding: .5em 1em;
    font-size: $font-size-large;
    line-height: $line-height-large;
  }
}

/* Tooltips */

.fr-tooltip {
  border-radius: $border-radius-small;
  color: $color-white;
  background-color: $color-black;
  font-size: $font-size-small;
  font-family: $font-family-base;
  line-height: $line-height-small;
  padding: $padding-xs-vertical $padding-xs-horizontal;
  @include reset-rendering();
}

/* Text Styles */

.fr-view {
  $list-padding: 40px;

  // assert the same list padding for user agents
  ul, ol {
    padding-left: $list-padding;
  }

  .rte-text-gray {
    color: $color-gray;
  }

  .rte-text-border {
    border-top: solid 1px $color-gray-light;
    border-bottom: solid 1px $color-gray-light;
    padding: $padding-small-vertical 0;
  }
  li.rte-text-border {
    position: relative;
    margin: 3px 0;
    padding: 3px 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -$list-padding;
      width: $list-padding;
      border-top: solid 1px $color-gray-light;
      border-bottom: solid 1px $color-gray-light;
    }
  }

  .rte-text-caps {
    font-variant: small-caps;
  }

  // contextual style classes
  // see: https://getbootstrap.com/docs/3.3/css/#helper-classes-backgrounds
  $contexts: (
    success: $state-success-bg,
    info: $state-info-bg,
    warning: $state-warning-bg,
    error: $state-danger-bg
  );

  // generate contextual style classes
  // this includes a fix for list items that have colorized bullets / numbers
  @each $context, $color in $contexts {
    .rte-text-#{$context} {
      background-color: $color;
      padding: $padding-small-vertical $padding-small-horizontal;
    }

    li.rte-text-#{$context} {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      z-index: 0;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -$list-padding;
        width: $list-padding;
        background-color: $color;
        z-index: -1;
      }
    }
  }
}

/* Image Styles */

$rte-img-hspace: 24px;
$rte-img-vspace: 12px;

.fr-view {
  img.rte-img-round,
  .rte-img-round img {
    border-radius: 12px;
  }

  img.rte-img-circle,
  .rte-img-circle img {
    border-radius: 50%;
  }

  img.rte-img-border,
  .rte-img-border img {
    padding: 4px;
    background-color: $color-white;
    border: 1px solid $color-gray-light;
  }

  img.rte-img-shadow,
  .rte-img-shadow img {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

/* Anchors */

coyo-rte .fr-view {
  *.fr-anchor[id] {
    border: 1px dashed $color-blue;
    padding: 4px;

    &:before {
      position: absolute;
      left: 0;
      width: 1.5em;
      content: url('data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20class%3D%22cis%20size-m%20ci-anchor%22%20fill%3D%22%23317DC1%22%3E%0A%20%20%3Cpath%20d%3D%22M19.39%2013.08a1%201%200%200%200-1.31.53%206.91%206.91%200%200%201-5.08%204.3V13h1a1%201%200%200%200%200-2h-1V9.82a3%203%200%201%200-2%200V11h-1a1%201%200%200%200%200%202h1v4.91a6.72%206.72%200%200%201-5.08-4.3%201%201%200%201%200-1.84.78A8.65%208.65%200%200%200%2012%2020a8.7%208.7%200%200%200%207.92-5.61%201%201%200%200%200-.53-1.31zM12%206a1%201%200%201%201-1%201%201%201%200%200%201%201-1z%22%2F%3E%0A%3C%2Fsvg%3E');
    }
  }

  *.fr-anchor[id]:not(img) {
    padding-left: calc(1.5em + 4px);
    position: relative;
  }
}

/* Table Styles */

.fr-view {
  table {
    @extend .table;
    table-layout: auto;

    &.fr-table {
      table-layout: fixed;
    }

    td, th {
      border: inherit;
      background: inherit;

      &.fr-selected-cell {
        border: 1px double $color-blue !important;
        background: transparentize($state-info-bg, .25);
      }
    }
  }

  .rte-table-striped {
    @extend .table-striped;
  }

  .rte-table-bordered {
    @extend .table-bordered;
  }

  .rte-table-condensed {
    @extend .table-condensed;
  }

  a.fr-anchor, &:focus, &[href=""] {
    color: inherit;
    cursor: default;
  }
}

/* Display Styles */

$rte-display-hspace: 24px;
$rte-display-vspace: 12px;

.fr-view {
  img.fr-cdbl, span.fr-img-caption.fr-cdbl,
  table.fr-cdbl {
    display: block;
    margin: ($rte-display-vspace * 0.5) auto ($rte-display-vspace * 0.5) 0;
  }
  img.fr-cdbc, span.fr-img-caption.fr-cdbc,
  table.fr-cdbc {
    display: block;
    margin: ($rte-display-vspace * 0.5) auto;
  }
  img.fr-cdbr, span.fr-img-caption.fr-cdbr,
  table.fr-cdbr {
    display: block;
    margin: ($rte-display-vspace * 0.5) 0 ($rte-display-vspace * 0.5) auto;
  }
  img.fr-cdil, span.fr-img-caption.fr-cdil,
  span.fr-video.fr-cdil,
  table.fr-cdil {
    display: inline-block;
    float: left;
    margin: ($rte-display-vspace * 0.5) ($rte-display-hspace * 0.5) ($rte-display-vspace * 0.5) 0 !important;
  }
  img.fr-cdir, span.fr-img-caption.fr-cdir,
  span.fr-video.fr-cdir,
  table.fr-cdir {
    display: inline-block;
    float: right;
    margin: ($rte-display-vspace * 0.5) 0 ($rte-display-vspace * 0.5) ($rte-display-hspace * 0.5);
  }

  span.fr-video.fr-cdbl,
  span.fr-video.fr-cdbc,
  span.fr-video.fr-cdbr {
    display: block;
    margin: ($rte-display-vspace * 0.5) 0;
  }

  span.fr-video.fr-cdbl {
    text-align: left;
  }
  span.fr-video.fr-cdbc {
    text-align: center;
  }
  span.fr-video.fr-cdbr {
    text-align: right;
  }

  @include sm-and-up() {
    img.fr-cdbl, span.fr-img-caption.fr-cdbl,
    span.fr-video.fr-cdbl,
    table.fr-cdbl {
      margin: $rte-display-vspace auto $rte-display-vspace 0;
    }
    img.fr-cdbc, span.fr-img-caption.fr-cdbc,
    span.fr-video.fr-cdbc,
    table.fr-cdbc {
      margin: $rte-display-vspace auto;
    }
    img.fr-cdbr, span.fr-img-caption.fr-cdbr,
    span.fr-video.fr-cdbr,
    table.fr-cdbr {
      margin: $rte-display-vspace 0 $rte-display-vspace auto;
    }
    img.fr-cdil, span.fr-img-caption.fr-cdil,
    span.fr-video.fr-cdil,
    table.fr-cdil {
      margin: $rte-display-vspace $rte-display-hspace $rte-display-vspace 0;
    }
    img.fr-cdir, span.fr-img-caption.fr-cdir,
    span.fr-video.fr-cdir,
    table.fr-cdir {
      margin: $rte-display-vspace 0 $rte-display-vspace $rte-display-hspace;
    }

    span.fr-video.fr-cdbl,
    span.fr-video.fr-cdbc,
    span.fr-video.fr-cdbr {
      margin: $rte-display-vspace 0;
    }
  }

  table.fr-cdbl,
  table.fr-cdbc,
  table.fr-cdbr,
  table.fr-cdil,
  table.fr-cdir {
    display: table;
  }

  blockquote {
    display: inline-block;
  }
}

/* Legacy styling for file downloads */

.fr-view {
  span[coyo-download] {
    cursor: pointer;
    color: $color-link;
  }
}

/* Fullscreen */
.fr-fullscreen-wrapper {
  position: relative;
}

/* List style fix COYOFOUR-15680 */
.fr-view ul, .fr-view ol {
  list-style-position: initial;
}

/* Inline image caption fix COYOFOUR-18121 */
span.fr-img-caption > span.fr-img-wrap > img {
  z-index: 1;
}
