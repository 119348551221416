// Image upload
// --------------------------------------------------

.image-upload {

  .drop-box {
    border: 5px dashed $color-gray;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 250px;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: $color-gray-lighter;
      cursor: pointer;
      border: 5px dashed $color-blue;
    }

    &.content-error {
      border: 5px dashed $color-red;
    }

    @media screen and (min-width: $screen-sm-min) {
      min-height: 300px;
    }

    @media print, screen and (min-width: $screen-md-min) {
      min-height: 400px;
    }
  }

  .drop-text {
    color: $color-gray;
    font-size: 60px;

    @media print, screen and (min-width: $screen-sm-min) {
      font-size: 24px;
    }
  }

  .drag-over {
    background-color: $color-gray-lighter;
    border: 5px dashed $color-blue;
  }

  .crop-area {
    height: 100%;
    min-height: 250px;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $screen-sm-min) {
      min-height: 300px;
    }

    @media print, screen and (min-width: $screen-md-min) {
      min-height: 400px;
    }
  }

}
