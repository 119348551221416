body {
  -ms-overflow-style: scrollbar;
  &.custom-theme-pending,
  &.translations-pending {
    .body {
      opacity: 0 !important;
    }
    > .cui-spinner {
      display: block;
      position: absolute !important;
      top: calc(50% - 8em);
      left: calc(50% - 8em);
    }
  }
  &:not(.custom-theme-pending):not(.translations-pending) > .cui-spinner {
    display: none !important;
  }

  &[class*='state-front'] .body {
    &.ng-enter {
      transition: 0.5s;
      .front-container {
        opacity: 0;
      }
    }
    &.ng-enter-active {
      .front-container {
        transition: opacity 0.5s;
        opacity: 1;
      }
    }
  }
}

.container-wrapper {
  align-items: stretch;
  display: flex;
  flex-flow: row;
  min-height: 100%;
  position: relative;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
