.image-header {
  background-color: $color-gray-light;
  position: relative;

  .sender-actions {
    z-index: 1;
    position: absolute;
    right: 8px;
    top: 8px;

    @media screen and (min-width: $screen-sm-min) {
      right: 12px;
      top: 12px;
    }

    @media print, screen and (min-width: $screen-md-min) {
      right: 24px;
      top: 16px;
    }

    > * {
      float: left;
      margin-right: 8px;

      @media print, screen and (min-width: $screen-sm-min) {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // panel below cover image
  .panel-foreground {
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media print, screen and (min-width: $screen-md-min) {
      border-radius: 0 0 5px 5px;
    }
  }
}
