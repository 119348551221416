.user-item {
  padding: 0 24px;

  @media print, screen and (min-width: $screen-md-min) {
    &:hover {
      background-color: $gray-lighter;
    }
  }

  &-link {
    display: flex;
    border-bottom: 1px solid $color-gray-lighter;
    padding: 16px 0;
  }

  &-container {
    margin-left: 12px;
    line-height: 16px;
  }

  &-headline {
    color: $text-color;
  }

  &-subline {
    color: $color-gray;
    font-size: 12px;
    overflow: hidden;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

coyo-user-item:last-of-type .user-item-link {
  border-bottom: 0;
}