@import "mixins";
@import "variables";

coyo-topic-settings {
  .topic-settings-container {
    .topic-header {
      cui-input-text {
        input {
          font-size: $font-size-h3;
        }
      }
    }
  }
}

