.workspaces-create {

  .coyo-wizard {
    margin: 0 36px;
  }

  .description {
    min-height: 42px;
  }
}
