.state-main-search {
  .search-term-panel {
    .panel-heading {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-term-column {
        display: flex;
        align-items: center;
        min-width: 0;

        &.column-no-shrink {
          flex-shrink: 0;
        }

        h1 {
          font-size: 22px;
          line-height: 28px;
          margin: 0;
          padding-right: 16px;
          @include ellipsis();
        }

        .hashtag-subscription-button {
          margin: 0;
        }

        .search-help-icon {
          margin-left: 8px;
        }

        .search-help-icon.icon-only-button {
            border-radius: 50%;
            margin-left: 0px;
        }
      }
    }
  }

  .result-group-heading {
    display: flex;
    justify-content: space-between;
    margin: 0;
    box-shadow: inset 0 -1px 0 0 $n50;
    background-color: $n0;
    font-size: 15px;

    &.panel-heading {
      padding: 24px 18px 24px 22px;
      @media screen and (min-width: $screen-sm-min) {
        padding: 24px 32px;
      }
    }

   &>* {
      display: flex;
      align-items: center;
    }

    h2 {
      padding-right: 4px;
      margin: 0;
      .result-group-headline {
        font-size: 15px;
        color: $n300;
        font-weight: 600;
      }
    }

    .show-all-results-text {
      text-align: right;
    }

    .zmdi-chevron-right {
        margin-right: -6px;
        text-align: center;
        width: 20px;
        font-size: 24px;
    }

    .result-group-icon {
      width: 24px;
      height: 24px;
      color: $color-gray;
      font-size: 24px;
      margin-right: 16px;
    }
  }

  .panel-heading {
    position: relative;
    border-bottom: 0;

    &:last-child {
      padding-bottom: 12px;
    }

    @media screen and (min-width: $screen-sm-min) {
      padding-bottom: 0;

      &:last-child {
        padding-bottom: 24px;
      }
    }
  }

  .search-result {
    border-bottom: 1px solid $color-gray-lighter;
    padding: 24px 0;
    @include break-word();

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
      border: 0;
    }

    &:hover:not(.no-link) {
      cursor: pointer;
    }

    .search-result-name {
      color: $text-color;
    }
  }

  .search-hint-tooltip-container {
    .tooltip {
      animation-name: zoomIn;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: 1;
      position: absolute;
      z-index: 1070;
      display: block;
      font-style: normal;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-spacing: normal;
      white-space: normal;
      opacity: 0;
      line-break: auto;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 400;
      word-wrap: break-word;
      word-break: break-word;
      border-radius: var(--cui-border-radius-m, 0.25rem);
      background-color: rgba(16, 29, 48, 0.85);
      color: #ffffff;
    }

    .tooltip.bottom {
      padding: 5px 0;
      margin-top: 3px;
    }

    .tooltip.in {
      opacity: 1;
    }

    .fade:not(.fade.in) {
      animation-name: zoomout;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: 1;
    }

    @keyframes zoomIn {
      from { transform: scale(0, 0); }
      to   { transform: scale(1, 1); }
    }

    @keyframes zoomout {
      from { transform: scale(1, 1); }
      to   { transform: scale(0, 0); }
    }

    .tooltip-inner {
      transition-duration: 300ms;
      transition-property: transform, visibility, opacity;
      max-width: 200px;
      padding: 3px 8px;
      color: #fff;
      text-align: center;
      border-radius: 4px;
    }
  }

  .search-result-body {
    padding-left: 4px;
    margin-bottom: -3px;
    display: flex;

    .search-result-icon {
      flex: none;
      margin-right: 24px;

      color: $color-gray;
      font-size: 24px;
      text-align: left;

      align-self: center;
    }

    .search-result-middle {
      flex: 1;
      min-width: 0;
    }

    .search-result-right {
      width: 100px;
      text-align: right;
    }

    .search-result-sender {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .search-result-excerpt {
      font-size: 14px;
      color: $color-gray;
      overflow: hidden;
      max-height: calc(4 * 14px * #{$line-height-base});
      line-height: $line-height-base;
    }

    .search-result-excerpt em, .search-result-name em {
      background-color: $color-text-highlight-bg;
      border-radius: 2px;
      font-style: normal;
    }

    @media print, screen and (min-width: $screen-sm-min) {
      .search-result-right {
        width: 150px;
      }

      .search-result-modified {
        width: 150px;
        flex: none;

        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.search-result-modified {
  font-size: 14px;
  color: $color-gray;
}

.search-term {
  display: flex;
}

.content-sidebar ~ .content-main .panel.empty-search-and-suggestions {
  @media print, screen and (min-width: $screen-sm-min) {
    width: 100%;
  }
}

.panel.empty-search-and-suggestions {
  width: 100%;
  @media print, screen and (min-width: $screen-sm-max) {
    margin: 0 auto 24px;
  }
  margin: 0 auto 12px;
  padding: 64px 0;
  text-align: center;

  .content-main.has-search-results & {
    @media print, screen and (min-width: $screen-sm-min) {
      width: 100%;
    }
    padding: 0;
    text-align: left;

    .suggestions-headline {
      font-size: $font-size-h4;
    }

    .suggestions {
      justify-content: left;
    }
  }

  .content-main:not(.has-search-results) & {
    .suggestions-wrapper {
      padding-top: 24px;
    }
  }

  .title {
    font-size: $font-size-h4;

    @media print, screen and (max-width: $screen-sm-max) {
      .bold {
        display: block;
      }
    }
  }

  .suggestions {
    display: flex;
    justify-content: center;

    .search-term {
      text-decoration: line-through;
    }

    .term-suggestions-separator {
      padding: 18px 8px;
    }

    .search-term,
    .suggestion {
      margin: 8px;
      background-color: $color-gray-lighter;
      padding: 4px 8px;
      border-radius: 8px;
    }

    .search-term {
      margin-left: 0;
    }
  }

  .reset-filters {
    display: block;
    margin-top: 16px;
  }

  .search-result-not-found {
    overflow-wrap: break-word;
  }
}
