.sidebar.messaging {
  .messaging-header.messaging-channel-header {
    display: flex;
    flex: none;

    .messaging-header-icon-bar {
      flex: none;

      &.pulse > a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 1px;
        background-color: $color-red;
        color: $color-white;
        border-radius: 50%;
        transition: color linear 0.5s;
        animation: pulse 1s infinite linear;
      }

      .context-menu {
        .context-menu-toggle {
          display: inline-block;
          font-size: 18px;
          color: $color-gray-light;
          &:hover {
            color: $color-gray;
          }
        }
      }
    }

    .messaging-title {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
      margin: 0 12px;
      outline: none;
      min-width: 0;

      cui-spinner {
        transform: scale(0.4);
      }

      .messaging-channel-avatar {
        flex: none;
      }

      .messaging-channel-name {
        text-transform: none;
        padding-left: 8px;
        line-height: 1.5;

        @include ellipsis();
      }
    }

    .messaging-channel-avatar {
      .user-avatar {
        .user-external {
          border-color: $color-messaging-sidebar-bg;
        }
      }
    }
  }
}
