.dynamic-app-table {
  padding: 5px 0 15px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  // .Table-header
  &-header {
    display: none;
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.05);
    padding: 25px 0;

    > .dynamic-app-table-row-item:last-child {
      padding-right: 35px;
    }

    > .dynamic-app-table-row-item:hover {
      cursor: pointer;
    }

    @media print, screen and (min-width: $screen-sm-min) {
      display: flex;
      color: $color-gray;
      text-transform: uppercase;
      padding: 25px 15px 0;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  // .Table-row
  &-row {
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.05);
    padding: 10px 0 0 0;

    &:nth-of-type(odd) {
      background-color: rgba(218, 218, 218, 0.1);
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover .options {
      opacity: 1;
    }

    @media print, screen and (min-width: $screen-sm-min) {
      display: flex;
      flex-flow: row nowrap;
      padding: 0 15px;

      &:hover {
        background-color: rgba(218, 218, 218, 0.25);
      }
    }

    @include xs() {
      padding: 10px 15px 10px 15px;
    }

    // .Table-row-item
    &-item {

      @media print, screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        display: inline-block;
      }

      min-width: 0px; // required for Edge so column does not overflow
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      flex-basis: 0;
      padding: 5px 0;
      @include break-word();

      &:before {
        content: attr(data-header);
        width: 100%;
        color: $color-gray;
        text-transform: uppercase;
        text-align: left;
        padding-right: 25px;
      }

      @media print, screen and (min-width: $screen-sm-min) {
        margin-right: 5px;
        &:before {
          content: none;
        }
      }

      & > * {
        min-width: 0px; // required for Edge so column does not overflow
      }
    }

    &-context-menu {
      width: 5px;
      cursor: pointer;
      padding-right: 20px;

      @media print, screen and (min-width: $screen-sm-min) {
        opacity: 0;
        padding-right: 0;
      }
    }

    &-show-details {
      padding-right: 20px;
      width: 20px;
      cursor: pointer;

      @media print, screen and (min-width: $screen-sm-min) {
        opacity: 0;
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      min-width: 35px;
    }
  }
}
