@import "mixins";

.event-create,
.event-settings {
  display: flex;
  justify-content: center;

  .input-group {
    &:not(.full-day) {
      coyo-date-picker {
        input {
          &:active,
          &:focus {
            border-right: 1px solid $color-primary;
          }

          border-right: 1px solid transparent;
        }
      }
    }
  }

  .panel {
    margin: auto;
    max-width: 700px;
    width: 100%;
    &.extended-panel {
      max-width: 100%;
    }
  }

  .panel-heading {
    border: none;
    .user-chooser-heading {
      width: 100%;
      text-align: center;
    }
  }

  .panel-body {
    padding-bottom: 32px;

    .user-chooser-wrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: $screen-sm-min) {
        flex-grow: 100;
      }

      button.user-chooser + span {
        display: inline-block;
      }
    }
  }

  .group {
    margin-bottom: 32px;
  }

  .participant-count {
    width: 10%;
    min-width: 76px;
  }

  .form-actions {
    padding: 32px;

    @media screen and (max-width: $screen-sm-min) {
      padding: 18px;
    }

    .form-action-buttons.text-right {
      button {
        margin-left: 8px;

        @media screen and (max-width: $screen-sm-min) {
          margin-left: 0;
        }
      }
    }
  }

  .advanced-settings {
    padding: 22px 16px;
    background-color: $n25; // should be $n35 but then the buttons not visible anymore
    margin: 0;

    label.headline {
      margin-bottom: 10px;
    }

    input {
      margin-left: 26px;
    }
  }

  .form-group {

    > .input-group-date-picker {
      float: left;
    }

    .participant-count {
      width: 10%;
      min-width: 76px;
    }

    .input-group-date-picker .input-group {
      display: block;

      .date {
        > input {
          width: 65%;
          border-radius: $border-radius-base 0 0 $border-radius-base;
        }
      }

      .time {
        > input {
          text-align: center;
          width: 35%;
          border-radius: 0 $border-radius-base $border-radius-base 0;
        }
      }

      &.full-day {
        .date {
          > input {
            width: 100%;
            border-radius: $border-radius-base $border-radius-base $border-radius-base $border-radius-base;
          }
        }

        .time {
          > input {
            display: none;
          }
        }
      }
    }
  }

  // should be moved later to _button.scss or removed/replaced completely
  @media screen and (max-width: $screen-sm-min) {

    .form-action-buttons {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      button {
        display: inline-block;
        width: 100%;
        margin-top: 8px;
      }

      &.text-right {
        button.coyo-btn {
          margin-left: 0;
        }
      }

      &.text-left {
        button.coyo-btn {
          margin-right: 0;
        }
      }
    }

    button.user-chooser {
      display: inline;
    }

    .user-chooser-count {
      text-align: center;
    }

    .event-user-chooser {

      .panel-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      #invited-people {
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 100;
      }
    }
  }
}

// TODO: remove this with coyo-ui v3.0.1, active color is fixed in this version
.mat-menu-panel {
  .mat-menu-item {
    &.active,
    &.active .mat-icon {
      color: $b400;
    }
  }
}

.event-create-menu-panel {
  &.mat-menu-panel {
    width: 300px;
    margin-top: 8px;
  }

  .mat-menu-content,
  .mat-menu-content:not(:empty) {
    padding: 8px;
  }
}

// fix for an angular material issue
mat-form-field[appearance="outline"] .mat-date-range-input-container {
  line-height: 1.375rem;
}
