body.state-front-terms {
  background-attachment: fixed;

  .front-container {
    max-width: 992px;

    @media screen and (max-width: $screen-sm-max) {
      -webkit-overflow-scrolling: touch;
    }

    .rte-html-container {
      background: lighten($color-gray-lighter, 2%);
      border: 1px solid $color-gray-light;
      border-radius: 5px;
      padding: 18px 24px;

      @media screen and (min-width: $screen-md-min) {
        max-height: 450px;
        overflow-y: auto;
      }
    }

    .form-actions {
      margin-top: 24px;
    }
  }
}

.terms .login-terms-header {
  display: flex;

  .login-terms-title {
    display: inline;
    flex: 1;
    margin-right: 20px;
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
    @include break-word();
  }

  @media print, screen and (min-width: $screen-md-min) {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
  }
}
