body[class*="state-front-"], body[class*="setup"] {
  height: auto;
  position: relative;

  .body {
    @include background-gradient-top-left-to-bottom-right($color-primary, $color-secondary);
    align-items: center;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .front-container {
    max-height: 100%;
    max-width: 500px;
    padding: 0 12px;
    width: 100%;

    h1.logo {
      background-image: $image-coyo-front;
      background-position: center;

      background-repeat: no-repeat;
      @include media-retina() {
        background-image: $image-coyo-front-hd;
      }

      background-size: contain;
      color: white;
      font-size: 60px;
      height: calc(var(--height-image-front) * 0.8);
      margin: 12px 0 30px;
      text-indent: -9999px;

      @media print, screen and (min-width: $screen-sm-min) {
        height: $height-image-front;
      }
    }

    .rte-html-container {
      @include break-word();
    }

    .panel {
      border-radius: 5px;

      .panel-body {
        > h2 {
          font-size: 24px;
          font-weight: 300;
          margin: 8px 0 24px;
          text-align: center;
        }
      }


      div.input-wrapper {
        position: relative;

        > .zmdi {
          display: none;
        }

        @media print, screen and (min-width: $screen-sm-min) {
          > .zmdi {
            border-right: 1px solid $color-gray-light;
            color: $color-gray;
            display: inline-block;
            font-size: 20px;
            height: 30px;
            left: 0;
            padding: 6px 0 6px 4px;
            position: absolute;
            text-align: center;
            top: 6px;
            width: 60px;
          }
        }
      }

      // scss-lint:disable QualifyingElement
      input[type='text'],
      input[type='email'],
      input[type='url'],
      input[type='password'] {

        @media print, screen and (min-width: $screen-sm-min) {
          padding-left: 73px;
        }

        &:focus {
          border: 1px solid $color-gray;

          + .zmdi {
            color: $color-primary;
          }
        }

        &:disabled {
          color: $color-gray-dark;
        }

        &.ng-valid {
          border-color: $color-valid;
        }
      }
      // scss-lint:enable QualifyingElement

      .error-message {
        background-color: $color-error-bg;
        border: 2px solid $color-error-border;
        border-radius: 5px 5px 0 0;
        color: $color-error;
        margin: 0;
        padding: 12px 0;
        text-align: center;
      }

      .panel-footer {
        background-color: $color-gray-lighter;
        padding-bottom: 12px;
        padding-top: 12px;
        text-align: left;

        a, a:visited, a:active {
          color: $color-gray-dark;

          &:hover {
            color: $color-gray-darker;
          }
        }
      }

      .copyright {
        background: url($image-coyo-signature) no-repeat center;
        background-size: 90px;

        height: 24px;
        position: relative;

        top: -2px;
        width: 90px;
      }
    }
  }
}
