.app-fields {

  .app-field-header {
    display: none;

    @media print, screen and (min-width: $screen-sm-min) {
      display: flex;
      border-bottom: 1px solid $color-gray-light;
      margin-top: 24px;

      .field-header {
        flex: 1;
        padding: 8px;
        text-transform: uppercase;
        color: $color-gray;
      }

      .field-header-type {
        text-indent: 75px;
      }
    }
  }

  .app-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    border-bottom: 1px solid $color-gray-light;

    &:hover {
      background-color: $color-gray-lighter;

      .field-handle, .field-options {
        visibility: visible;
      }
    }

    &:last-child {
      border: 0;
    }

    .field-icon {
      flex: 0 0 75px;
      color: $color-gray-darker;
    }

    .field-type {
      display: none;

      @media print, screen and (min-width: $screen-sm-min) {
        display: inline;
        flex: 1;
      }
    }

    .field-name {
      flex: 1;
      font-size: 17px;
      font-style: italic;
      overflow: hidden;
      text-overflow: ellipsis;

      @media print, screen and (min-width: $screen-sm-min) {
        font-size: 16px;
      }
    }

    .field-handle, .field-options {
      color: $color-gray-darker;

      @media print, screen and (min-width: $screen-sm-min) {
        visibility: hidden;
      }
    }
  }

}

.app-field-types {

  .app-field-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px 24px;
    border-top: 1px solid $color-gray-light;

    &:hover {
      cursor: pointer;
      background-color: $color-gray-lighter;

      @media print, screen and (min-width: $screen-sm-min) {
        .field-type-arrow {
          visibility: visible;
        }
      }
    }

    @media print, screen and (min-width: $screen-sm-min) {
      padding: 12px;
      flex-wrap: nowrap;
    }

    &:first-child {
      border: 0;
    }

    .field-type-icon {
      flex: 0 0 40px;
      color: $color-gray-darker;
    }

    .field-type-title {
      flex: 0 0 17%;
      font-size: 18px;

      @media print, screen and (min-width: $screen-sm-min) {
        font-size: 16px;
      }
    }

    .field-type-description {
      flex: 1 0 100%;
      color: $color-gray-dark;
      padding-top: 4px;
      line-height: 22px;

      @media print, screen and (min-width: $screen-sm-min) {
        flex: 2;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .field-type-arrow {
      display: none;

      @media print, screen and (min-width: $screen-sm-min) {
        display: inline;
        color: $color-gray-dark;
        visibility: hidden;
      }
    }

  }
}

.panel.configure-fields {

  .panel-heading {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    border: 0;

    .panel-heading-item {
      padding: 12px;
      border-left: 1px solid $color-gray-light;

      &:first-child {
        border: none;
      }
    }

    &.panel-heading-configure {
      justify-content: space-between;
    }
  }

  h3 {
    border-bottom: solid $color-gray-light 1px;
    margin-top: 10px;
    padding-bottom: 10px;

    @media print, screen and (min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }
}

.app-no-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .app-icon-big {
    background-color: $color-gray-lighter;
    margin: 25px 0 0;
    padding: 15px;
    border-radius: 100%;

    @media print, screen and (min-width: $screen-sm-min) {
      margin: 25px 0 0;
    }

    i {
      color: $color-gray;
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 42px;
    }
  }

  .app-empty-text {
    width: 100%;
    color: $color-gray;
    text-align: center;
    margin: 15px 0;
    @include break-word();
  }

  .btn-configure {
    margin: 20px 0 15px;
  }
}

.app-entry-form {

  .form-group {
    margin: 0;
    > div:first-child {
      text-align: left;
    }
  }

  .app-entry-form-label-row {
    margin-top: 10px;

    @media print, screen and (min-width: $screen-md-min) {
      margin-top: 2px;
    }

    .description-text {
      font-size: 13px;
    }
  }

  label.control-label {
    overflow-x: hidden;
    text-overflow: ellipsis;
    @media print, screen and (min-width: $screen-sm-min) {
      font-size: 16px;
      text-align: left;
    }
  }

  textarea {
    resize: none;
  }

  .ui-select-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-gray;

    .caret {
      color: $color-black;
    }

    .ui-select-match-text {
      color: $color-black;
    }
  }
}

.search-wrapper.panel-heading-item {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  color: $text-color;

  a.btn-zmdi {
    padding: 0;
    margin: 0;
    color: $color-gray-darker;
  }

  input[type="search"] {
    color: $input-color;
    background-color: transparent;
    border: 0;
    outline: 0;
    flex: 1;
    margin-left: 6px;
    padding: 0;

    &::-webkit-input-placeholder {
      color: $color-gray;
    }
    &:-moz-placeholder {
      color: $color-gray;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: $color-gray;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $color-gray;
    }
  }

  .btn-reset {
    margin-right: -16px;
    visibility: hidden;

    &.is-visible {
      visibility: visible;
    }
  }
}
