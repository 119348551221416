coyo-blog-article-view {
  .teaser {
    img {
      -o-object-fit: cover;
      width: 100%;
      object-fit: cover;
      height: 160px;
    }
  }
}
