.panel-footer.panel-footer-status {
  display: flex;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 12px;

  .status-options-left,
  .status-options-right {
    display: flex;
    align-items: center;
  }

  .status-options-right {
    margin-left: auto;
  }
}

.panel .panel-footer.panel-footer-status {
  padding: 8px 20px;

  @include xs() {
    padding: 4px 8px;
  }
}
