.user-chooser-item {
  display: flex;
  margin: 0 0 10px;
  padding: 8px;
  flex-direction: row;
  flex: 100%;
  overflow: hidden;

  @media screen and (min-width: $screen-xs-min) {
    flex: 49% 0;
  }
  @media print, screen and (min-width: $screen-md-min) {
    flex: 32% 0;
  }

  &:hover {
    cursor: pointer;
  }

  .item-info {
    flex: 1;
    align-self: center;
    margin-left: 16px;
    overflow: hidden;

    .item-subline {
      color: $color-gray;
      font-size: 13px;
      line-height: 18px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .user-avatar {
    width: 60px;
    height: 60px;
    align-self: center;
    position: relative;
  }

  .group-avatar {
    font-size: 24px;
    background-color: $n35;
    color: $n200;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.new, &.removed, &.existing  {
    .user-avatar::after {
      display: inline-block;
      background-color: $color-green;
      border: 2px solid white;
      border-radius: 100%;

      font-family: 'Material-Design-Iconic-Font';
      font-size: 12px;
      color: $color-white;
      text-align: center;

      position: absolute;
      bottom: -2px;
      right: -2px;

      width: 22px;
      height: 22px;
      line-height: 20px;
    }

    &.existing {
      .user-avatar::after {
        content: '\f26b';
      }
    }

    &.new {
      .user-avatar::after {
        content: '\f278';
      }
    }

    &.removed {
      .user-avatar::after {
        background-color: $color-red;
        content: '\f136';
      }
    }
  }
}