@import "mixins";

// ===== Responsive

[hidden] {
  display: none !important;
}

.hidden-sm-and-down {
  @include sm-and-down() {
    display: none !important;
  }
}

.hidden-md-and-up {
  @include md-and-up() {
    display: none !important;
  }
}

.hidden-sm-and-up {
  @include sm-and-up() {
    display: none !important;
  }
}
