.fl-image-picker {

  ul.fl-image-picker-list {
    width: 100%;
    float: left;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;

    li {
      background-color: $color-gray-lighter;
      float: left;
      margin: 0 10px 10px 0;
      width: 160px;
      height: 160px;
      padding: 8px;
      position: relative;
      border: $color-gray-light solid 1px;
      border-radius: 5px;

      .image-preview {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .remove-file {
        position: absolute;
        top: -12px;
        right: -8px;
        color: $color-gray;

        &:hover {
          color: $color-red;
        }
      }

    }
  }

}