@mixin coyo-button-variant(
  $color, $background, $border,
  $hover-color, $hover-background, $hover-border,
  $focus-color, $focus-background, $focus-border,
  $pressed-color, $pressed-background, $pressed-border,
  $disabled-color, $disabled-background, $disabled-border,
  $active-color, $active-background, $active-border
) {
  color: $color;
  background-color: $background;
  border-color: $border;

  > coyo-icon svg {
    fill: $color;
  }

  &:focus {
    color: $focus-color;
    background-color: $focus-background;
    border-color: $focus-border;

    > coyo-icon svg {
      fill: $focus-color;
    }
  }
  @at-root .dropdown.open > #{&}.dropdown-toggle,
  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;

    > coyo-icon svg {
      fill: $hover-color;
    }
  }
  &:active {
    color: $pressed-color;
    background-color: $pressed-background;
    border-color: $pressed-border;

    > coyo-icon svg {
      fill: $pressed-color;
    }
  }
  &.active {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    > coyo-icon svg {
      fill: $active-color;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus > * {
      color: $disabled-color;
      background-color: $disabled-background;
      border-color: $disabled-border;

      > coyo-icon svg {
        fill: $disabled-color;
      }
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

@mixin coyo-button-size(
  $padding-vertical, $padding-horizontal,
  $font-size, $line-height, $border-radius,
  $icon-size, $icon-space, $icon-only-size
) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;

  > .zmdi,
  > .zmdi-hc-stack {
    margin-right: $icon-space;
    font-size: $icon-size;
    line-height: $line-height;

    &.right {
      margin-right: 0;
      margin-left: $icon-space;
    }
  }

  > coyo-icon svg {
    margin-right: $icon-space;
    width: $icon-size;
    height: $icon-size;
  }


  > coyo-icon.right svg {
    margin-right: 0;
    margin-left: $icon-space;
  }

  &.coyo-btn-icon {
    padding: $padding-vertical;

    > .zmdi,
    > .zmdi-hc-stack {
      margin-right: 0;
      width: $line-height;
      font-size: $icon-only-size;
    }

    > coyo-icon svg {
      margin: #{($line-height - $icon-only-size) * 0.5};
      width: $icon-only-size;
      height: $icon-only-size;
    }
  }
}

@mixin coyo-btn-link-text() {
  span {
    @include ellipsis();
    flex: 1 1 0;
  }
}
