.page-subscribe {
  .ng-animate {
    transition: none;
    animation: none;
  }
}

a.list-subscribe {
  i.zmdi-notifications-active {
    color: $color-link;
  }
}