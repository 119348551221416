/* Grid modification: .col-xl
 *
 * This file utilizes the defined grid functions shipped with
 * bootstrap-sass via copy-paste. It has not been beautified
 * manually.
------------------------------------------------------------ */

// Extra large screen / extra wide desktop
//** Deprecated `$screen-xl` as of v3.0.1
@use "sass:math";

$screen-xl:                  1400px !default;
$screen-xl-min:              $screen-xl !default;
//** Deprecated `$screen-xl-desktop` as of v3.0.1
$screen-xl-desktop:          $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-lg-max:              ($screen-xl-min - 1) !default;

// Large screen / wide desktop
$container-extra-large-desktop: (1340px + $grid-gutter-width) !default;
//** For `$screen-xl-min` and up.
$container-xl:                  $container-extra-large-desktop !default;

.container {
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}

// Generate the extra large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter * 0.5);
  padding-right: ($gutter * 0.5);

  @media (min-width: $screen-xl-min) {
    float: left;
    width: percentage(math.div($columns, $grid-columns));
  }
}
@mixin make-xl-column-offset($columns) {
  @media (min-width: $screen-xl-min) {
    margin-left: percentage(math.div($columns, $grid-columns));
  }
}
@mixin make-xl-column-push($columns) {
  @media (min-width: $screen-xl-min) {
    left: percentage(math.div($columns, $grid-columns));
  }
}
@mixin make-xl-column-pull($columns) {
  @media (min-width: $screen-xl-min) {
    right: percentage(math.div($columns, $grid-columns));
  }
}

@mixin make-grid-columns-xl($i: 1, $list: ".col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width * 0.5));
    padding-right: floor(($grid-gutter-width * 0.5));
  }
}
@include make-grid-columns-xl;

@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}


@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-xl-min) {
  @include responsive-visibility('.visible-lg');
}
.visible-xl-block {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
.visible-xl-inline {
  @media (min-width: $screen-xl-min) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include responsive-invisibility('.hidden-lg');
}

@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.hidden-xl');
}



/* Gutter adjustments
------------------------------------------------------------ */

@include sm-and-down {
  .container,
  .container-fluid {
    padding-left: 8px;
    padding-right: 8px;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  [class*='col-'] {
    padding-left: 8px;
    padding-right: 8px;
  }
}
