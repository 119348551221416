.account-settings {
  .section-wrapper, coyo-settings-password {
   p {
     margin-bottom: 0;
   }
  }
}

// Manually adjust the layout of badges in the timezone selection field
.pl-badges.timezone-badges {
  display: flex;
  align-items: center;
  width: 100%;

  cui-badge {
    margin-left: auto;
  }
}
