.ng-select.hashtag-select {
  .ng-clear-wrapper {
    display: none;
  }
}

html .ng-select.hashtag-select.mat-ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  align-items: center;
  padding: 0 0 0 6px;
}
