// Media query helpers
// ============================================

// breakpoint helpers based on bootstrap's breakpoints
// --------------------------------------------

@mixin xs {
  @media #{screen} and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media #{screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media #{screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media #{screen} and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media #{screen} and (min-width: #{$screen-xl-min}) {
    @content;
  }
}


// inclusive mixins
// --------------------------------------------

@mixin sm-and-down {
  @media #{screen} and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin sm-and-up {
  @media #{screen} and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md-and-down {
  @media #{screen} and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin md-and-up {
  @media #{screen} and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg-and-down {
  @media #{screen} and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin lg-and-up {
  @media #{screen} and (min-width: #{$screen-lg-min}) {
    @content;
  }
}


// Mixins - Set @media retina
// --------------------------------------------------

// example usage:
// @include media-retina() {
//  background-image: url($image-coyo-hd);
// }
@mixin media-retina() {
  // scss-lint:disable VendorPrefix
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content
  }
  // scss-lint:enable VendorPrefix
}
