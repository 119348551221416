// ===== Delete when the material migration is finished =====
@import "variables";
@import "mixins";

html {
  font-size: 16px;
}

:root {
  --cui-primary-bg: var(--btn-primary-bg);
  --cui-primary-bg-hover: hsl(var(--btn-primary-bg-h), var(--btn-primary-bg-s), calc(var(--btn-primary-bg-l) - 10%));
  --cui-primary-bg-active: hsl(var(--btn-primary-bg-h), var(--btn-primary-bg-s), calc(var(--btn-primary-bg-l) - 10%));
  --cui-primary-fill: var(--btn-primary-color);
  --cui-primary-fill-hover: var(--btn-primary-color);
  --cui-primary-fill-active: var(--btn-primary-color);
  --cui-primary-text: var(--link-color);
  --cui-primary-text-hover: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 15%));
  --cui-primary-text-active: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 15%));
}

a.mat-button-base {
  outline: 0;
}

//--- reverts general bootstrap styles

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $color-background-main;
}

label {
  margin-bottom: initial;
}

.mat-checkbox label {
  max-width: initial;
  font-weight: normal;
}

//--- ensure that the new CDK overlay container is above old bootstrap stuff.

.cdk-overlay-container {
  z-index: 1500;
}

.cdk-drag-preview {
  z-index: 3000 !important;
}

.modal.image-widget-file-library-modal {
  z-index: 1550 !important;
}

//--- style new cards like old bootstrap panels

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-accordion {
  .mat-expansion-panel:not(:first-child):not(.mat-expanded) {
    border-top: 1px solid $coyo-border-color;
  }
}

//--- override radio button styles of bootstrap which interfere with material styles

.mat-radio-label {
  max-width: initial;
  margin-bottom: initial;
  font-weight: initial;
}

//--- ensure that top level modals (modals that do not open additional modals) are always on top of all other modals

.top-level-modal {
  z-index: 1900 !important;
}

.top-level-modal.modal-backdrop {
  z-index: 1899 !important;
}

//--- revert badge changes

.pl-badges .badge {
  position: initial;
  top: initial;
}

//--- setup ngSelect styles for Angular material

.mat-form-field {
  @import '~@ng-select/ng-select/scss/material.theme';
}

.mat-form-field.inline-form-field {
  margin: 0;

  .mat-form-field-wrapper {
    padding: 0;
  }
}

.ng-select.mat-ng-select .ng-select-container .ng-value-container {
  .ng-value, .ng-input, .ng-placeholder {
    align-self: center;
  }
}

cui-spinner {
  &.inverted-spinner {
    color: rgba(255, 255, 255, 0.25);
  }
}

:where(.cui-menu-item) label {
  margin-bottom: 0;
}
