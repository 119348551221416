.navbar-main {
  background-color: $coyo-navbar;
  height: $navbar-height;
  margin: 0;
  border: none;
  z-index: $zindex-topnav;

  @media print, screen and (min-width: $screen-lg-min) {
    position: static;
    box-shadow: 0 3px 0 0 $color-navbar-border;
  }

  .navbar-wrapper {
    display: flex;
    height: 100%;
  }

  .navbar-wrapper > .nav {
    display: flex;

    .nav-item {
      div.nav-item-brand {
        height: 100%;
      }

      .logo-image {
        padding: 0;
      }

      .nav-item-brand {
        width: var(--width-navbar-brand);
        background: transparent $image-coyo-nav center/contain no-repeat;
        text-indent: -99999px;

        @include media-retina() {
          background: transparent $image-coyo-nav-hd center/contain no-repeat;
        }
      }

      // override for bootstrap dropdown which is not outlined on focus
      &.nav-item-profile {
        a:focus {
          outline: 5px auto -webkit-focus-ring-color;
          outline-offset: -2px;
        }
      }
    }
  }

  .navbar-wrapper > .nav .nav-item,
  .navbar-wrapper > .nav-search .input-wrapper {
    > a {
      display: flex;
      height: 100%;
      align-items: center;
      color: $coyo-navbar-text;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: .025em;
      transition: all linear 0.1s;
      transition-property: background-color, color;

      .zmdi {
        font-size: 18px;
        font-weight: normal;
      }
    }

    > a:hover, > a:focus, > a:hover:focus {
      background-color: darken($coyo-navbar, 7.5%);
      color: $coyo-navbar-text;
    }

    &.active > a, &.active > a:hover, &.active > a:focus, &.active > a:hover:focus,
    &.open > a, &.open > a:hover, &.open > a:focus {
      background-color: $coyo-navbar-active;
      color: $coyo-navbar-text-active;
    }

    &.nav-item-icon {
      > a {
        padding-left: 12px;
        padding-right: 12px;

        @media print, screen and (min-width: $screen-md-min) {
          padding-left: 14px;
          padding-right: 14px;
        }

        .zmdi {
          font-size: 20px;
          font-weight: normal;
        }
      }

      .nav-item-label {
        display: none;
      }

      .search-icon-close {
        position: absolute;
        height: 36px;
        right: 8px;
        top: 7px;
        background-color: transparent !important;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

    &.nav-item-no-link a {
      cursor: default;
      background-color: $coyo-navbar !important;
      color: $coyo-navbar-text !important;
    }
  }

  .navbar-wrapper .nav-search {
    flex: 1 1 auto;
    position: relative;

    .input-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .form-control {
        background-color: darken($coyo-navbar, 7.5%);
        color: $coyo-navbar-text;
        border: none;
        border-radius: 18px;
        box-shadow: none;
        padding: 5px 16px;
        height: 36px;
        margin: 0 8px;
        @include placeholder-color(transparentize($coyo-navbar-text, 0.5));

        &:active, &:focus {
          box-shadow: 0 0 0 2px $coyo-navbar-active;
          @include placeholder-color(transparentize($coyo-navbar-text, 0.25));
        }

        @media print, screen and (min-width: $screen-md-min) {
          opacity: 0;
          max-width: 0;
          transition: all ease-in-out .3s;
          transition-property: opacity, max-width;

          &.open {
            opacity: 1;
            max-width: 100%;
          }
        }
      }
    }
  }

  .coyo-c:before {
    height: 22px;
    width: 10px;
    content: url($image-coyo-c);
    display: inline-block;
    position: relative;
    margin-right: 8px;
  }
}

.navbar-wrapper {
  max-width: $layout-max-width;
  margin: 0 auto;

  @media print, screen and (min-width: $screen-md-min) {
    padding-left: 48px;
    padding-right: 48px;
  }
}
