.page {

  .titles-container {
    display: flex;
    flex-direction: row;
    .title-description-wrapper {
      flex: 1;
      min-width: 0;
      padding-right: 5px;
      @include break-word();

      .title {
        white-space: normal;
        margin-bottom: 10px;
      }
      .description {
        color: $color-gray;
        white-space: pre-wrap;
      }
    }

    .page-labels {
      display: flex;
      align-items: center;
      justify-content: center;
      &.visible-xs > .label {
        margin-right: 4px;
      }
      &.hidden-xs > .label {
        margin-left: 4px;
      }
      coyo-page-visibility {
        margin-right: 12px;
      }
    }
  }

  .sender-actions {
    .btn {
      padding: 5px 13px;

      @media print, screen and (min-width: $screen-sm-min) {
        padding: 8px 16px;
      }
    }
  }

  .image-header {

    .page-avatar-container {
      position: relative;
      margin: -17px 0 12px 16px;
      height: 92px;
      max-height: 92px;

      @media screen and (min-width: $screen-sm-min) {
        height: 120px;
        max-height: 120px;
        margin: -60px 0 16px 24px;
      }

      @media screen and (min-width: $screen-md-min) {
        height: 120px;
        max-height: 120px;
        margin: -54px 0 16px 24px;
      }

      @media print, screen and (min-width: $screen-lg-min) {
        height: 160px;
        max-height: 160px;
        margin: -94px 0 16px 24px;
      }

      coyo-avatar-overlay {
        position: absolute;
        border-radius: 0 0 5px 5px;
      }
    }

    .sender-avatar {
      border: 3px solid $color-white;
      background-color: $color-page-avatar-bg;
      border-radius: 5px;
      width: 92px;
      height: 92px;
    }
  }

  .heading-title {
    font-size: .9375rem;
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}
