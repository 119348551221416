.important-profile-fields {
  margin-bottom: 0;

  li {
    margin: 20px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-label {
    text-transform: uppercase;
    color: $color-gray;

  }

  &-value {
    margin: 6px 0;
  }
}
