// Image Crop
// --------------------------------------------------

.image-crop {

  .crop-area {
    height: 100%;
    min-height: 250px;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $screen-sm-min) {
      min-height: 300px;
    }

    @media print, screen and (min-width: $screen-md-min) {
      min-height: 400px;
    }
  }

}
