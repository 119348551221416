// mobile navigation
.sender-navigation-modal {
  @include ellipsis();

  div.nav-sender-title {
    width: 100%;
    padding: 0;
  }

  h2 {
    &.title {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      color: $color-gray;
      padding-bottom: 16px;
      padding-top: 10px;
      font-size: .9375rem;
      line-height: 1.5rem;
    }
  }

  > input, a, .app-group-translations {
    color: dimgrey;
    padding-top: 10px;
  }

  .modal-body {
    background-color: $color-white;
    border-top: 1px solid $color-gray-lighter;

    hr.divider {
      margin: 10px -25px 20px -25px;
    }

    > div {
      > div {
        padding-bottom: 12px;

        &.app-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 6px;
        }
      }

      &.apps-options {
        padding-bottom: 12px;
        padding-left: 6px;
      }
    }

    li {
      padding-top: 3px;
    }

    span {
      font-size: 15px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  div.app-title {
    &.app-inactive > a {
      color: $color-gray-light;
    }

    &.active.app-inactive > a {
      color: inherit;
    }
  }

  .btn-nav-cancel {
    margin-bottom: 5%;
    margin-left: 5%;
    width: 90%
  }

  h5.nav-sender-options {
    text-transform: uppercase;
    color: $color-gray-darker;
    padding-bottom: 16px;
  }

}