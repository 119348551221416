// Mixins - Styles for dropdowns
// --------------------------------------------------

// example usage:
// @include dropdown()
@mixin dropdown() {
  border-radius: 5px;
  background-color: $dropdown-bg;
  border: 1px solid $dropdown-fallback-border; // IE8 fallback
  border: 1px solid $dropdown-border;
  @include box-shadow(0, 1px, 1px, 0, rgba(0, 0, 0, .05));
  cursor: default;
  overflow: hidden;
}
