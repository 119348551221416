.btn-mobile-label {
  display: none;

  @media print, screen and (min-width: $screen-sm-min) {
    display: inline;
    margin-left: 4px;
  }
}

.btn-right {
  @media print, screen and (min-width: $screen-sm-min) {
    float: right;
  }
}