.championship-app,
.championship-app-settings,
.championship-app-modal {

  .games,
  .ranking {
    margin-top: 1em;
  }

  .game-row {
    position: relative;
    padding: 1.5em 0;
    border-bottom: 1px solid $color-border;
    margin-left: 0;
    margin-right: 0;

    &:last-child {
      border-bottom: none;
    }

    .col-md-2,
    .col-md-10,
    .col-md-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .game-date {
    font-weight: 600;
    margin-top: 2.5em;
    background: #f2f3f4;
    padding: 8px 16px;
    border-radius: 4px;
  }

  .game-time {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .game {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .75em;
    margin-bottom: .75em;
    position: relative;


    .home-team,
    .away-team {
      width: 50%;
      display: flex;
      align-items: center;

      .name-long {
        display: none;
      }

      @include md-and-up() {
        .name-short {
          display: none;
        }

        .name-long {
          display: inline;
        }
      }

      .score {
        min-width: 2em;
        text-align: center;
      }

      .score-input {
        height: 2em;
        width: 2em;
        line-height: 2em;
        text-align: center;
        padding: 0;

        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        &.ng-invalid {
          border: 2px solid $color-red;
          box-shadow: none;
        }
      }

      .country-flag {
        width: 1.5em;
        line-height: 1.5em;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        margin: 0 1em;

        @include md-and-up() {
          width: 2em;
          line-height: 2em;
          margin: 0 1em 0 2.5em;
        }
      }

      .flag-icon-empty {
        color: $color-gray-dark;
        background-color: $color-white;
        border-radius: 50%;
        text-align: center;
      }
    }

    .home-team {
      flex-direction: row-reverse;
      .country-flag {
        @include md-and-up() {
          margin: 0 2.5em 0 1em;
        }
      }
    }

    .team-divider {
      min-width: 1em;
      text-align: center;
    }

    .saved-indicator {
      position: absolute;
      bottom: -2em;
      visibility: hidden;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
    }

    @include keyframes(pulse-feedback) {
      0%, 100% {
        visibility: hidden;
        opacity: 0;
      }
      25%, 75% {
        visibility: visible;
        opacity: 1;
      }
    }

    .saved-success, .saved-error {
      @include animate(pulse-feedback 2.5s);
    }
  }

  .game-points {
    text-align: center;

    @include md-and-up() {
      position: absolute;
      right: 1em;
      top: 4.5em;
      display: flex;
      height: 48px;
      align-items: center;
    }
  }

  .game-color-0 { color: $color-red; }
  .game-color-1 { color: $color-orange; }
  .game-color-2 { color: $color-yellow; }
  .game-color-3 { color: $color-green; }

  .pagination {
    margin-bottom: 0;
  }

  .ranking {
    .rank,
    .points {
      min-width: 4em;
      text-align: center;

      @include md-and-up() {
        min-width: 6em;
      }
    }

    .player {
      width:100%;
      max-width:1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dots {
      text-align: center;
    }

    .user-avatar {
      margin: -3px .75em -3px 0;
    }
  }

  .user {
    height: 60px;
    width: 200px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  .user-info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .user-header {
    font-weight: bold;
  }
}

.championship-app-settings {
  .game-row {
    padding: .75em 0 .25em;
  }

  .game-edit {
    text-align: center;
    margin-top: -.5em;
  }

  .game-settings .col-sm-5 {
    margin-bottom: 8px;
  }
}

.championship-app-modal {
  .user-header,
  .user-footer {
    display:flex;
    flex-flow: row wrap;

    .user-label {
      margin-left: 10px;
      color: $color-white;
      text-decoration: none;

      @include sm-and-up() {
        color: $color-black;
      }
    }
  }


  .game-date {
    margin-top: 0;
  }

  .game-row {
    padding: 1em 0;

    .game {
      margin: 0;
    }
    .game-points {
      top: 3.25em;
    }
  }

}
